import React from 'react';
import { MDBRow, MDBCol, MDBContainer, MDBBtn } from 'mdb-react-ui-kit';
import Video from './Video';
import { Link } from 'react-router-dom';
import HeadingAnimation from '../Animations/HeadingAnimation';
import image from "../Home1/QUALITY-MATERIALS.jpg"
import image1 from '../Home1/EXCEPTIONAL-CRAFTSMANSHIP.jpg'


export default function QualityMaters() {
    const UrlData1 = "https://lgdus.co/skylab_shape/QualityMaters1.mp4";
    const UrlData2 = "https://lgdus.co/skylab_shape/QualityMaters2.mp4";


    return (
        <MDBContainer fluid className='text-center'>
            <MDBRow>
                <MDBCol md='6' data-aos="fade-up" className='d-flex'>
                    {/* <Video videoUrl={UrlData1} /> */}
                    <img src={image} alt="Quality Maters" className='img-fix'/>

                </MDBCol>
                <MDBCol md='6' className='py-3 d-flex' data-aos="fade-up">
                    <div className='d-flex justify-content-center align-items-center h-100'>

                        <MDBContainer id='QualityMaterialsTextBox'>
                            <p id="TTFonts" className='fs-1'><HeadingAnimation text="QUALITY MATERIALS" /></p>
                            <p id="DancingFonts" >From our diamonds to our jewelry, we work with only the best materials available. Every step from diamond manufacturing to design to final polishing is done in house, reflecting our dedication to crafting each piece with expertise.</p>
                            <Link to='/skylabcommitment' onClick={() => { window.scrollTo({ top: 1000, left: 0, behavior: 'smooth' }) }}> <button className="hoverButtonClassname" id='hoverButton'><span id='hoverButtonSpan'>LEARN MORE</span></button></Link>
                        </MDBContainer>

                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md='6' className='py-3' data-aos="fade-up">
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <MDBContainer id='QualityMaterialsTextBox'>
                            <p id="TTFonts" className='fs-1'><HeadingAnimation text="EXCEPTIONAL CRAFTSMANSHIP" /></p>
                            <p id="DancingFonts" >Our artisans pour genuine passion into crafting every product, guaranteeing a lifetime of exceptional craftsmanship and sincere commitment.</p>
                            <Link to='/skylabcommitment' onClick={() => { window.scrollTo({ top: 1600, left: 0, behavior: 'smooth' }) }}><button className="hoverButtonClassname" id='hoverButton'><span id='hoverButtonSpan'>LEARN MORE</span></button></Link>
                        </MDBContainer>
                    </div>
                </MDBCol>
                <MDBCol md='6' data-aos="fade-up">
                    <img src={image1} alt="Quality Maters" className='img-fix' />
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}
import React, { useEffect, useState } from 'react';
import { CCloseButton, CNavItem, CNavbarNav, COffcanvas, COffcanvasBody, COffcanvasHeader } from '@coreui/react';
import { Link, NavLink } from 'react-router-dom';
import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBNavbar, MDBNavbarToggler, MDBRow } from 'mdb-react-ui-kit';


const MegaNavbars = () => {
  const [visible, setVisible] = useState(false);
  const [navSize, setNavSize] = useState("5rem");
  const [navColor, setNavColor] = useState("transparent");
  const [textColor, setTextColor] = useState("text-white");
  const [navLogo, setNavLogo] = useState("skylabLogoWhite.png");
  const [nav2Logo, setNav2Logo] = useState("none");
  const [topNavbar, setTopNavbar] = useState("block");
  const [navMargin, setNavMargin] = useState("-20px");
  const [sideNavColor, setSideNavColor] = useState("rgba(252, 254, 255, 0.465)");
  const [isMobileView, setIsMobileView] = useState(false);
  const [loginmenu, setLoginmenu] = useState("block");
  const [isProduct, setIsProduct] = useState(false);
  const [isFoundation, setIsFoundation] = useState(false);
  const [isAbout, setIsAbout] = useState(false);

  const listenScrollEvent = () => {
    const scrollY = window.scrollY;
    setSideNavColor(scrollY > 10 ? "rgba(252, 254, 255, 0.565)" : "rgba(252, 254, 255, 0.465)");
    setNavColor(scrollY > 10 ? "black" : "transparent");
    setNavSize(scrollY > 10 ? "5rem" : "5rem");
    setTextColor(scrollY > 10 ? "text-white" : "text-white");
    setNavLogo(scrollY > 10 ? "skylabLogo.png" : "skylabLogoWhite.png");
    setNav2Logo(scrollY > 10 ? "block" : "none");
    setTopNavbar(scrollY > 10 ? "none" : "block");
    setNavMargin(scrollY > 10 ? "-100px" : "0px");
    setLoginmenu(scrollY > 10 ? "none" : "block");
    if (isMobileView) {
      setNavMargin(scrollY > 10 ? "-95px" : "0px");
    }
  };

  const handleResize = () => {
    const tabletBreakpoint = 768;
    setIsMobileView(window.innerWidth <= tabletBreakpoint);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const toggleDropdown = (dropdown) => {
    setIsProduct(dropdown === 'product' ? !isProduct : false);
    setIsFoundation(dropdown === 'foundation' ? !isFoundation : false);
    setIsAbout(dropdown === 'about' ? !isAbout : false);
    
  };
  

  const mobileNavItemStyle = isMobileView ? { fontSize: '20px', padding: '300', marginBottom: '20px' } : {};



// --------------------------hover to Mobile App----------------------
const [isHovered, setIsHovered] = useState(false);

const handleMouseEnter = () => {
  setIsHovered(true);
};

const handleMouseLeave = () => {
  setIsHovered(false);
};

  return (
    <>
      <span style={{ display: topNavbar }}>
        <div className='container-fluid d-flex justify-content-center pt-2' style={{ backgroundColor: navColor, height: navSize, transition: "all 1s", position: "fixed", zIndex: '1' }}>
          <NavLink to='/'><img src={navLogo} alt="" className='navbar-logo' /></NavLink>
        </div>
      </span>

      <MDBNavbar expand="xl" className='w-100' style={{ backgroundColor: navColor, height: navSize, transition: "all 1s", position: "fixed", top: "100px", marginTop: navMargin, zIndex: '4' }}>
        <MDBContainer fluid>
          <NavLink to='/'><img src='./skylabLogoWhite.png' alt="" className='px-5 navbar-second-logo ' style={{ display: nav2Logo }} /></NavLink>
          <MDBNavbarToggler
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
            onClick={() => setVisible(!visible)}
            style={{ backgroundColor: 'rgba(252, 254, 255, 0.9)' }} />


          <COffcanvas id="offcanvasNavbar2" style={{ backgroundColor: sideNavColor }} placement="end" portal={false} visible={visible} onHide={() => setVisible(false)}>
            <COffcanvasHeader>
              <CCloseButton className="text-reset" onClick={() => setVisible(false)} />
            </COffcanvasHeader>
            <COffcanvasBody className='d-flex justify-content-center'>
              <CNavbarNav style={mobileNavItemStyle}>
                <CNavItem>
                  <NavLink onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }} id='navbar-name' to="/" className={'text-decoration-none text-light ${textColor} hover-underline-animation'}>Home</NavLink>
                </CNavItem>
                {/* <CNavItem >
                  <NavLink style={{ display: loginmenu }} onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }} id='navbar-name' to="http://eshop.skylabdiamond.com" className={'text-decoration-none text-light ${textColor} hover-underline-animation'}>Login</NavLink>
                </CNavItem> */}
                <CNavItem>
                  <NavLink onClick={() => toggleDropdown('product')} id='navbar-name' className={'text-decoration-none text-light ${textColor} hover-underline-animation '}>Product <MDBIcon fas icon="caret-down" /></NavLink>
                  
                  <div className={`border-top-light dropdown-menu w-100 w ${isProduct ? 'show' : ''}`} aria-labelledby="navbarDropdown" style={{ borderTopLeftRadius: '0', borderTopRightRadius: '0', backgroundColor: 'black' }}>
                    <div className="container">
                      <div className="row my-4">
                        <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                          <div className="list-group list-group-flush bg-image hover-overlay shadow-1-strong rounded ripple mb-4" data-mdb-ripple-color="light">
                            <div className='bg-image hover-zoom' style={{cursor:'pointer'}}>
                              <img src="OurCommitment.jpg" className="img-fluid" />
                            </div>
                            <Link to='/product' onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}><MDBBtn onClick={() => toggleDropdown('product')} outline className='mt-4 w-100 ' color='light'>Product</MDBBtn></Link>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
                          <div className="text-light list-group-flush my-5">
                            <Link to='/product' onClick={() => { window.scrollTo({ top: 1000, left: 0, behavior: 'smooth' }) }} className="list-group-item list-group-item-action fs-5 hover-underline-animation"><span  onClick={() => toggleDropdown('product')}>Loose Diamonds</span></Link>
                          </div>
                            
                          <div className="text-light list-group-flush mt-5">
                            <Link to='/product' onClick={() => { window.scrollTo({ top: 2500, left: 0, behavior: 'smooth' }) }} className="list-group-item list-group-item-action fs-5 hover-underline-animation"><span  onClick={() => toggleDropdown('product')}>Fancy Color Diamonds</span></Link>
                          </div>
                         
                        
                        </div>
                        <div className="col-md-6 col-lg-3">
                        <div className="text-light list-group-flush mt-5">
                            <Link to='/product' onClick={() => { window.scrollTo({ top: 1300, left: 0, behavior: 'smooth' }) }} className="list-group-item list-group-item-action fs-5 hover-underline-animation"><span  onClick={() => toggleDropdown('product')}>Diamond Jewelry</span></Link>
                          </div>
                      
                          <div className="text-light list-group-flush my-5">
                            <Link to='/product' onClick={() => { window.scrollTo({ top: 3700, left: 0, behavior: 'smooth' }) }} className="list-group-item list-group-item-action fs-5 hover-underline-animation"><span  onClick={() => toggleDropdown('product')}>Layout Diamonds</span></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CNavItem>
                <CNavItem>
                  <NavLink onClick={() => toggleDropdown('about')} id='navbar-name' className={'text-decoration-none text-light ${textColor} hover-underline-animation'}>About Us <MDBIcon fas icon="caret-down" /></NavLink>
                  <div className={`border-top-light dropdown-menu w-100 ${isAbout ? 'show' : ''}`} aria-labelledby="navbarDropdown" style={{ borderTopLeftRadius: '0', borderTopRightRadius: '0', backgroundColor: 'black' }}>
                    <div className="container">
                      <div className="row my-4">
                        <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                          <div className="list-group list-group-flush bg-image hover-overlay shadow-1-strong rounded ripple mb-4" data-mdb-ripple-color="light">
                            <div className='bg-image hover-zoom' style={{cursor:'pointer'}}>
                              <img src="ourStoryFrame.jpg" className="img-fluid" />
                            </div>
                            <Link to='/about' onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}><MDBBtn onClick={() => toggleDropdown('about')} outline className='mt-4 w-100' color='light'>About Us</MDBBtn></Link>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
                          <div className="text-light list-group-flush my-5">
                            <Link to='/about' onClick={() => { window.scrollTo({ top: 450, left: 0, behavior: 'smooth' }) }} className="list-group-item list-group-item-action fs-5 hover-underline-animation"><span  onClick={() => toggleDropdown('about')}>Skylab's Story </span></Link>
                          </div>
                          <div className="text-light list-group-flush mt-5">
                            <Link to='/about' onClick={() => { window.scrollTo({ top: 1300, left: 0, behavior: 'smooth' }) }} className="list-group-item list-group-item-action fs-5 hover-underline-animation"><span  onClick={() => toggleDropdown('about')}>Skylab's Commitment </span></Link>
                          </div>
                          <div className="text-light list-group-flush my-5">
                            <Link to='/about' onClick={() => { window.scrollTo({ top: 2100, left: 0, behavior: 'smooth' }) }} className="list-group-item list-group-item-action fs-5 hover-underline-animation"><span  onClick={() => toggleDropdown('about')}>Skylab's Process Of Brilliance </span></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CNavItem>
                <CNavItem>
                  <NavLink onClick={() => toggleDropdown('foundation')} id='navbar-name' className={'text-decoration-none text-light ${textColor} hover-underline-animation'}>Foundation <MDBIcon fas icon="caret-down" /></NavLink>
                  <div className={`border-top-light dropdown-menu w-100 ${isFoundation ? 'show' : ''}`} aria-labelledby="navbarDropdown" style={{ borderTopLeftRadius: '0', borderTopRightRadius: '0', backgroundColor: 'black' }}>
                    <div className="container">
                      <div className="row my-4">
                        <div className="col-md-12 col-lg-4  mb-3 mb-lg-0">
                          <div className="list-group list-group-flush bg-image hover-overlay shadow-1-strong rounded ripple mb-4" data-mdb-ripple-color="light">
                            <div className='bg-image hover-zoom' style={{cursor:'pointer'}}>
                              <img src="schoolImage-2.png" className="img-fluid" />
                            </div>
                            <Link to='/foundation' onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}><MDBBtn onClick={() => toggleDropdown('foundation')} outline className='mt-4 w-100' color='light'>Foundation</MDBBtn></Link>
                          </div>
                        </div>

                        <div className="col-md-12 col-lg-4 mb-3 mb-md-0">
                        <div className="text-light list-group-flush my-5">
                            <Link to='/foundation' onClick={() => { window.scrollTo({ top: 1000, left: 0, behavior: 'smooth' }) }} className="list-group-item list-group-item-action fs-5 hover-underline-animation"><span  onClick={() => toggleDropdown('foundation')}>Skylab Foundation</span></Link>
                          </div>
                          <div className="text-light list-group-flush my-3">
                            <Link to='/foundation' onClick={() => { window.scrollTo({ top: 1600, left: 0, behavior: 'smooth' }) }} className="list-group-item list-group-item-action fs-5 hover-underline-animation"><span  onClick={() => toggleDropdown('foundation')}>Schools & Educational Initiatives</span></Link>
                          </div>
                        
                          <div className="text-light list-group-flush my-5">
                            <Link to='/foundation' onClick={() => { window.scrollTo({ top: 3500, left: 0, behavior: 'smooth' }) }} className="list-group-item list-group-item-action fs-5 hover-underline-animation"><span  onClick={() => toggleDropdown('foundation')}>Collaborations</span></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CNavItem>
                <CNavItem>
                  <NavLink onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }} id='navbar-name' to="/privacy-policy" className={'text-decoration-none text-light ${textColor} hover-underline-animation'}>Privacy Policy</NavLink>
                </CNavItem>
                <CNavItem>
                  <NavLink onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }} id='navbar-name' to="/contact" className={'text-decoration-none text-light ${textColor} hover-underline-animation'}>Contact</NavLink>
                </CNavItem>
              </CNavbarNav>
            </COffcanvasBody>
          </COffcanvas>

        </MDBContainer>
        <span  id='navbarLoginIcon'>
          <NavLink onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }} id='navbar-name' to="https://eshop.skylabdiamond.com/" className={'text-decoration-none text-light ${textColor}'}><MDBIcon far icon="user-circle" className='fs-4' /></NavLink>
        </span>
      </MDBNavbar>

      {/* <div id='dropup-mobileApp'>
            <div className="hover-dropup-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <button className="hover-dropup-button border-light border"><MDBIcon fas icon="download" /></button>
              {isHovered && (
                <div className="hover-dropup-content">
                  <Link to='https://apps.apple.com/us/app/skylab-diamond/id1534936741'><MDBBtn id='app-dropup-btn' className='bg-light border'><MDBIcon fab icon="apple" /></MDBBtn></Link>
                  <Link to='https://play.google.com/store/apps/details?id=com.skylabdiamond.android&pcampaignid=web_share'><MDBBtn id='app-dropup-btn' className='bg-light border'><MDBIcon fab icon="android" /></MDBBtn></Link>
                  <MDBBtn id='app-dropup-btn' className='bg-light border' data-bs-toggle="modal" data-bs-target="#appQrData"><MDBIcon fas icon="tablet-alt" /></MDBBtn>
                </div>
              )}
            </div>
      </div> */}




      {/* -----------------------------------------------App QR Code with Link popup-------------------------------------------- */}

      <div className="modal fade " id="appQrData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Download App</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
             <MDBRow>
                <MDBCol className='d-flex justify-content-center align-items-center my-2'>
                    <img src="iskylabQRApp.png" alt="iskylabQRApp"  className='w-75'/>
                </MDBCol>
                <MDBCol className='d-flex justify-content-center align-items-center my-2'>
                    <h2>Or</h2>
                </MDBCol>
                <MDBCol className='d-flex justify-content-center align-items-center my-2'>
                    <img src="iskylabClickApp.png" alt="iskylabClickApp"  className='w-75'/>
                </MDBCol>
             </MDBRow>
             <div className='bg-black w-100 text-light text-center py-3'>
                <p>We are also available on...</p>
                <div>
                <Link to='https://apps.apple.com/us/app/skylab-diamond/id1534936741'><MDBBtn className='bg-black mx-2 border'><MDBIcon fab icon="apple" /></MDBBtn></Link>
                <Link to='https://play.google.com/store/apps/details?id=com.skylabdiamond.android&pcampaignid=web_share'><MDBBtn className='bg-black mx-2 border'><MDBIcon fab icon="android" /></MDBBtn></Link>
               
                </div>
             </div>
             
          </div>
        </div>
      </div>
    </div>
      
    </>
  );
};

export default MegaNavbars;

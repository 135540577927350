import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'
import HeadingAnimation from '../Animations/HeadingAnimation';

export default function LearnMore(props) {
    const rowStyle1 = {
        background: 'url(storyLearnMore2.png)',
        backgroundSize: '100% 100%', 
      };
    const rowStyle2 = {
        background: 'url(storyLearnMore1.png)',
        backgroundSize: '100% 100%', 
      };
  return (
    <>
      <MDBContainer>
            <MDBRow >
                <MDBCol lg='6' md='6' className='d-flex justify-content-center align-items-center' data-aos="fade-up">
                    <div className='container' id='OurPhiloshpy-text-box'>
                        <p className='fs-1 text-center'><HeadingAnimation text={props.heading1}/></p>
                        <p id='DancingFonts' className='text-center fs-5'>{props.discription1}</p>
                    </div>  
                </MDBCol>
                <MDBCol lg='6' md='6' className='my-5 py-5' style={rowStyle1} data-aos="fade-up">
                    <div className='bg-image hover-zoom' style={{cursor:'pointer'}}>
                        <img src={props.images1} alt=""  className='w-100'  loading="lazy"/>
                    </div>
                </MDBCol>
            </MDBRow>

            <MDBRow >
                <MDBCol lg='6' md='6' className='my-5 py-5' style={rowStyle2} data-aos="fade-up">
                    <div className='bg-image hover-zoom' style={{cursor:'pointer'}}>
                        <img src={props.images2} alt=""  className='w-100'  loading="lazy"/>
                    </div>
                </MDBCol>
                <MDBCol lg='6' md='6' className='d-flex justify-content-center align-items-center' data-aos="fade-up">
                    <div className='container' id='OurPhiloshpy-text-box'>
                        <p className='fs-1 text-center '><HeadingAnimation text={props.heading2}/></p>
                        <p id='DancingFonts' className='text-center fs-5' >{props.discription2}</p>
                    </div>  
                </MDBCol>
            </MDBRow>
            
            
      </MDBContainer>
    </>
  )
}

import React, { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { Tooltip } from 'react-tooltip'
import HeadingAnimation from '../Animations/HeadingAnimation';



const Shapedata = (data) => {
    const [modal , setModel] = useState(false)
  return (  

    <>
            
                <Modal size='lg' isOpen={modal} toggle={()=>setModel(!modal)} style={{paddingTop:"8%", width:"93.5%"}}>
                  <ModalHeader  toggle={()=>setModel(!modal)}>
                  </ModalHeader>      
                  <ModalBody className='bg-black'>
                    <video width="100%" height="500" autoPlay controls loop>
                        <source src={data.videolnk}  type="video/mp4"/>
                    </video>
                  </ModalBody>
                </Modal>
           
                    

                        <div className='pointers mb-2' onClick={()=>setModel(true)} data-aos="fade-up">
                                <h6 style={{color:"black", }} className='shap-text' id="DancingFonts">{data.name}</h6>
                            <a data-tooltip-id="my-tooltip-inline" data-tooltip-content="360 View">
                                <img className='dimnd-sep my-3' src={data.image} alt='Shapes of Dimond' /><br/> 
                                <MDBIcon far icon="play-circle" id='VideoPointers'/>   
                            </a>
                            <Tooltip id="my-tooltip-inline" place='bottom-start' />
                        </div>
                        
                    

                        
                    
    </>
  )
}




 const ShapeofDiamond=()=> {
    
  return (<> 
    
    
    <MDBContainer fluid className='pb-5 bg-white '>
    <div className=''>
        <p className='text-center fs-1  text-black' id="TTFonts"><HeadingAnimation text="Diamonds"/></p>
        {/* <p className='text-center fs-1  text-black' id="TTFonts">Shop By Shapes</p> */}
    </div> 


      <MDBRow className='mb-5 text-center pt-0'>
            <MDBCol size='4' lg='2' sm='3' md='2' xl='1' xs='4' className='grid container pointers '>
                <Shapedata  image="1.png"  name="Round" videolnk="https://lgdus.co/skylab_shape/Round.mp4"/>
            </MDBCol>
            
            <MDBCol size='4' lg='2' sm='3' md='2' xl='1' xs='4' className='grid container pointers '>
                <Shapedata  image="2.png"  name="Princess" videolnk="https://lgdus.co/skylab_shape/Princess.mp4"/>      
            </MDBCol>
            
            <MDBCol size='4' lg='2' sm='3' md='2' xl='1' xs='4' className='grid container pointers '>
                <Shapedata  image="3.png"  name="Emerald" videolnk="Emerald.mp4"/>      
            </MDBCol>
            
            <MDBCol size='4' lg='2' sm='3' md='2' xl='1' xs='4' className='grid container pointers '>
                <Shapedata  image="4.png"  name="Asscher" videolnk="https://lgdus.co/skylab_shape/Asscher.mp4"/>      
            </MDBCol>
            
            <MDBCol size='4' lg='2' sm='3' md='2' xl='1' xs='4' className='grid container pointers '>
                <Shapedata  image="5.png"  name="Oval" videolnk="Ohttps://lgdus.co/skylab_shape/Oval.mp4"/>      
            </MDBCol>
            
            <MDBCol size='4' lg='2' sm='3' md='2' xl='1' xs='4' className='grid container pointers '>
                <Shapedata  image="6.png"  name="Cushion" videolnk="https://lgdus.co/skylab_shape/Cushion.mp4"/>      
            </MDBCol>
            
            <MDBCol size='4' lg='2' sm='3' md='2' xl='1' xs='4' className='grid container pointers '>
                <Shapedata  image="7.png"  name="Pear" videolnk="https://lgdus.co/skylab_shape/Pear.mp4"/>      
            </MDBCol>

            <MDBCol size='4' lg='2' sm='3' md='2' xl='1' xs='4' className='grid container pointers '>
                <Shapedata  image="8.png"  name="Heart" videolnk="https://lgdus.co/skylab_shape/Heart.mp4"/>      
            </MDBCol>

            <MDBCol size='4' lg='2' sm='3' md='2' xl='1' xs='4' className='grid container pointers '>
                <Shapedata  image="9.png"  name="Marquise" videolnk="https://lgdus.co/skylab_shape/Marquise.mp4"/>      
            </MDBCol>

            <MDBCol size='4' lg='2' sm='3' md='2' xl='1' xs='4' className='grid container pointers '>
                <Shapedata  image="10.png"  name="Radiant " videolnk="https://lgdus.co/skylab_shape/Rediyant.mp4"/>      
            </MDBCol>

      </MDBRow>
      <hr class="hr-text" data-content="★"/>
      </MDBContainer>
    

      
    </>);
}




export default ShapeofDiamond
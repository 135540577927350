import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'
import HeadingAnimation from '../Animations/HeadingAnimation'

export default function TimesofIndia() {
  return (
    <MDBContainer className=''>
                    <div className='pt-5 text-center'>
                        <span className='fs-4  text-black' id="TTFonts"><HeadingAnimation text='Article – By Times of India'/></span>
                        <p id='DancingFonts'>Know the story behind this contribution:<br/> Diamond baron to rebuild alma mater - http://toi.in/OkcDqZ16/a31gj</p>
                    </div>
            <MDBRow>
            <MDBCol md='6' lg='6' className='pt-5' data-aos="fade-up">
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-center'>
                            <p className='text-start fs-5 '><HeadingAnimation text='School Transformation'/></p>
                            <p id='DancingFonts' className='text-start'>Over the years, we have completely transformed Sir G.N. Goti Kendravarti School in Vallabhipur, Bhavnagar District, Gujarat, Veer Demalu Memorial College and Pranabananda Vidya Mandir School, both located in Assam, India.. What was once a modest institution is now a cornerstone of hope for the community, providing quality education in an inspiring setting.</p>
                            <p className='text-start fs-5'><HeadingAnimation text='STEM Pioneers'/></p>
                            <p id='DancingFonts' className='text-start'>Our STEM initiatives have sparked the interest of students in this school, laying the foundation for future innovators.</p>
                        </div>
                    </div>
                </MDBCol>
                <MDBCol md='6' lg='6' data-aos="fade-up">
                    <div style={{ overflowY: 'scroll', height: '60vh' }}>
                        <img src="TimesofIndia1.jpg" alt="" className='w-100' />
                        <img src="TimesofIndia2.jpg" alt="" className='w-100' />
                    </div>
                </MDBCol>
            </MDBRow>
            <hr class="hr-text" data-content="★"/>

    </MDBContainer>
  )
}

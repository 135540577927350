import React, { useState, useEffect } from "react";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";
import { MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import HeadingAnimation from "../Animations/HeadingAnimation";

export default function YourServices() {
  const [goToSlide, setGoToSlide] = useState(0);
  const [offsetRadius] = useState(2);
  const [configState] = useState(config.gentle);
  const [isLarge, setIsLarge] = useState(true);

  const slides = [
    {
      key: uuidv4(),
      content: (
        <div className="text-center mx-5" id="atYourService">
          <MDBIcon className="fs-1 p-3" fas icon="shipping-fast" />
          <p id="TTFonts" className="text-center fs-5">
            Express Delivery Options
          </p>
        </div>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <div className="text-center mx-5" id="atYourService">
          <MDBIcon className="fs-1 p-3" fas icon="newspaper" />
          <p id="TTFonts" className="text-center fs-5">
            Certified Products
          </p>
        </div>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <div className="text-center mx-5" id="atYourService">
          <MDBIcon className="fs-1 p-3" fas icon="credit-card" />
          <p id="TTFonts" className="text-center fs-5">
            Payment Methods
          </p>
        </div>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <div className="text-center mx-5" id="atYourService">
          <MDBIcon className="fs-1 p-3" fas icon="hand-holding-usd" />
          <p id="TTFonts" className="text-center fs-5">
            Easy Financing{" "}
          </p>
        </div>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <div className="text-center mx-5" id="atYourService">
          <MDBIcon className="fs-1 p-3" fas icon="exchange-alt" />
          <p id="TTFonts" className="text-center fs-5">
            Memo – Consignment
          </p>
        </div>
      ),
    },
    {
      key: uuidv4(),
      content: (
        <div className="text-center" id="atYourService">
          <MDBIcon className="fs-1 p-3" fas icon="dollar-sign" />
          <p id="TTFonts" className="text-center fs-5 ">
            Competitive Pricing{" "}
          </p>
        </div>
      ),
    },
  ].map((slide, index) => ({
    ...slide,
    onClick: () => setGoToSlide(index),
  }));

  useEffect(() => {
    const handleResize = () => {
      setIsLarge(window.innerWidth >= 720);
    };

    // Set initial size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize); // Cleanup on unmount
  }, []);
  return (
    isLarge && (
      <MDBContainer className="p-5">
        <div className="text-center p-5">
          <p className="fs-1" id="TTFonts">
            <HeadingAnimation text="At Your Service" />
          </p>
          <hr className="hr-text" data-content="★" />
        </div>
        <MDBContainer className="my-5">
          <Carousel
            slides={slides}
            goToSlide={goToSlide}
            offsetRadius={offsetRadius}
            animationConfig={configState}
          />
        </MDBContainer>
      </MDBContainer>
    )
  );
}

// import React, { useState, useEffect } from "react";
// import Carousel from "react-spring-3d-carousel";
// import { v4 as uuidv4 } from "uuid";
// import { config } from "react-spring";
// import { MDBContainer, MDBIcon } from "mdb-react-ui-kit";
// import HeadingAnimation from "../Animations/HeadingAnimation";

// export default function YourServices() {
//   const [goToSlide, setGoToSlide] = useState(0);
//   const [offsetRadius] = useState(2);
//   const [configState] = useState(config.gentle);

//   // Slides definition (no click functionality)
//   const slides = [
//     {
//       key: uuidv4(),
//       content: (
//         <div className="text-center" id="atYourService">
//           <MDBIcon className="fs-1 p-3" fas icon="shipping-fast" />
//           <p id="TTFonts" className="text-center fs-5">Express Delivery Options</p>
//         </div>
//       ),
//     },
//     {
//       key: uuidv4(),
//       content: (
//         <div className="text-center" id="atYourService">
//           <MDBIcon className="fs-1 p-3" fas icon="newspaper" />
//           <p id="TTFonts" className="text-center fs-5">Certified Products</p>
//         </div>
//       ),
//     },
//     {
//       key: uuidv4(),
//       content: (
//         <div className="text-center" id="atYourService">
//           <MDBIcon className="fs-1 p-3" fas icon="credit-card" />
//           <p id="TTFonts" className="text-center fs-5">Payment Methods</p>
//         </div>
//       ),
//     },
//     {
//       key: uuidv4(),
//       content: (
//         <div className="text-center" id="atYourService">
//           <MDBIcon className="fs-1 p-3" fas icon="hand-holding-usd" />
//           <p id="TTFonts" className="text-center fs-5">Easy Financing </p>
//         </div>
//       ),
//     },
//     {
//       key: uuidv4(),
//       content: (
//         <div className="text-center" id="atYourService">
//           <MDBIcon className="fs-1 p-3" fas icon="exchange-alt" />
//           <p id="TTFonts" className="text-center fs-5">Memo – Consignment</p>
//         </div>
//       ),
//     },
//     {
//       key: uuidv4(),
//       content: (
//         <div className="text-center" id="atYourService">
//           <MDBIcon className="fs-1 p-3" fas icon="dollar-sign" />
//           <p id="TTFonts" className="text-center fs-5">Competitive Pricing </p>
//         </div>
//       ),
//     },
//   ];

//   // Auto-slide effect using useEffect
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setGoToSlide((prev) => (prev + 1) % slides.length);
//     }, 3000); // Auto-slide every 3 seconds

//     return () => clearInterval(interval); // Cleanup on component unmount
//   }, [slides.length]);

//   return (
//     <MDBContainer className="p-5">
//       <div className="text-center p-5">
//         <p className="fs-1" id="TTFonts">
//           <HeadingAnimation text="At Your Service" />
//         </p>
//         <hr className="hr-text" data-content="★" />
//       </div>
//       <MDBContainer className="my-5">
//         <Carousel
//           slides={slides}
//           goToSlide={goToSlide}
//           offsetRadius={offsetRadius}
//           animationConfig={configState}
//         />
//       </MDBContainer>
//     </MDBContainer>
//   );
// }

import React from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import HeadingAnimation from '../Animations/HeadingAnimation';

const instaStories = [
  { id: 1, imgSrc: 'instaImage1.jpg', link: 'https://www.instagram.com/p/C0BMXp1SLU3/?img_index=2' },
  { id: 2, imgSrc: 'instaImage2.jpg', link: 'https://www.instagram.com/p/Cz22JV7t3Jm/?img_index=3' },
  { id: 2, imgSrc: 'instaImage3.jpg', link: 'https://www.instagram.com/p/C0DvwJGNywN/?img_index=2' },
  { id: 2, imgSrc: 'instaImage1.jpg', link: 'https://www.instagram.com/p/C0BMXp1SLU3/?img_index=2' },
  // Add more items as needed
];

export default function InstaStory() {
  const maskStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    display: 'none', // Hide the mask by default
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };

  const handleHover = (event) => {
    event.currentTarget.querySelector('.mask').style.display = 'block';
  };

  const handleLeave = (event) => {
    event.currentTarget.querySelector('.mask').style.display = 'none';
  };

  return (
    <div>
    <MDBRow className='px-3 my-5'>
    <div className='text-center p-5'>
      <p className='fs-1' id="TTFonts"><HeadingAnimation text="Instagram"/></p>
      <hr class="hr-text" data-content="★"/>
    </div>
      {instaStories.map((story) => (
        <MDBCol lg='3' md='3' key={story.id} data-aos="fade-up">
          <div
            className='bg-image'
            style={{ position: 'relative', overflow: 'hidden' }}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            id='instaStoryBox'
          >
            <img src={story.imgSrc} className='img-fluid' alt='Sample' />
            <div className='mask' style={maskStyle}>
              <div className='d-flex justify-content-center align-items-center h-100 border border-top-0 border-bottom-0 border-light mx-3'>
                <div>
                  {/* <p className='mb-0'>{story.text}</p> */}
                  <Link className='text-light fs-4 text-decoration-none' to={story.link}><MDBIcon far icon="heart" />  </Link>
                </div>
              </div>
            </div>
          </div>
        </MDBCol>
      ))}
    </MDBRow>
    </div>
  );
}

// http://meraj.mypressonline.com/
import React from 'react'
import Video from './Home1/Video';
import ChildAnEducation from './Foundation/ChildAnEducation';
import TimesofIndia from './Foundation/TimesofIndia';
import Footers from './Navbar&Footer/Footer';
import BackImageTopText from './Foundation/BackImageTopText';
import School from './Foundation/School';
import MegaNavbars from './Navbar&Footer/MegaNavbars';
import HeadingAnimation from './Animations/HeadingAnimation'
import { MDBIcon } from 'mdb-react-ui-kit';
// import video from "Foundation-Cover.gif";

export default function Foundation() {
    const UrlData1 = "Foundation-Cover.jpg";

  return (
    <>
     {/* <div>
        <Navbars/>
    </div> */}
    <div> 
        <MegaNavbars/>
    </div>
    <div id='TopScroll'>
      
        <img src={UrlData1} alt="Foundation Cover"   loading="lazy" />
    </div>
    <div>
            <div className='py-5 text-center'>
                <p className='fs-1  text-black' id="TTFonts"><HeadingAnimation text="Skylab Foundation"/></p>
                <p id='DancingFonts' className='fs-5'>“Giving a Child an Education Is by Far One of the Most Important Investments We Can Make"</p>
                <hr class="hr-text" data-content="★"/>
            </div>
            <ChildAnEducation/>
    </div>
    <div>
            <div className='pt-5 text-center'>
                <p className='fs-2  text-black' id="TTFonts"><HeadingAnimation text="Supporting Schools and Educational Initiatives"/></p>
                <hr class="hr-text" data-content="★"/>
            </div>
        <School/>
    </div>
    <div>
        <TimesofIndia/>
    </div>

    <div className='pb-5'>
        <p className='fs-2  text-black text-center' id="TTFonts">Collaborations</p>
        <BackImageTopText backImage='loresimage.png'
            heading1='Skylabʼs Support for Laureus: Empowering Young Live'
            description1='Skylab proudly stands as a supporter of Laureus, a global force dedicated to transforming the lives of youth through sports and education. With Laureusʼ unwavering support weʼve made a significant impact in our communities. Welcome to Skylab, where purpose and passion unite to shape a brighter future for the youth.'
            heading2='Transformacion Social AC (TRASO)'
            description2='TRASO, an initiative under Laureus, operates in one of Mexico Cityʼs most marginalized and challenging neighborhoods. Focusing on children aged 5-12, TRASO uses the sport of boxing to address deep-rooted social issues in the Tacubaya community. With bi-weekly boxing sessions, group therapy, psychological support, and educational workshops, TRASO is committed to making a lasting impact. Laureusʼ support for TRASO further enhances our mission to create positive change through sports and education, providing young individuals with a path to a brighter future.'
            heading3='Sport for Education and Sustainable Livelihoods in Africa (SESLA)'
            description3='Laureusʼ SESLA initiative responds to the unique challenges facing Africa, where formal employment opportunities remain limited despite economic growth and increased education access. SESLA empowers African youth through sports-based education and livelihood programs. Over the next two years, SESLA will equip up to 10,000 young individuals with essential skills to improve their lives and communities. With over 30 projects across Africa, SESLA enhances sporting access and imparts vital values and life skills. For instance, in the Democratic Republic of Congo, SESLAʼs initiative will provide over 2,000 young people in an impoverished community with new skills and opportunities, highlighting the substantial impact of Laureusʼ donation to SESLA.'

        /> 
    </div>
    <div>
        <BackImageTopText backImage='kcaimage.png'
            heading1='Skylabʼs Contribution to Rebuilding Kibbutz Beʼeri: Fostering Positive Transformation'
            description1='At Skylab, we take great pride in our role as proud contributors to the rebuilding of Kibbutz Beʼeri, where our impact has been truly meaningful. Our commitment extends far beyond financial support, reflecting our unwavering dedication to fostering unity and driving positive change. Through our involvement, we have played a significant part in bringing about tangible improvements, instilling hope, and promoting unity within Kibbutz Beʼeri. Our commitment to creating a brighter tomorrow is an ongoing journey. We invite you to join us in this noble cause, whether by directly supporting Kibbutz Beʼeri or exploring how your organization can make a positive impact through corporate social responsibility. Skylab, where purpose meets passion, is where we stand together to create a better world.'
            heading2=''
            description2=''
            heading3=''
            description3=''
        /> 
    </div>
    <div>
        <BackImageTopText backImage='kbimage.png'
            heading1='Skylabʼs Support to Keep a Child Alive: Saving Young Lives/ Empowering Young Lives with Purpose'
            description1='Skylab proudly partners with Keep a Child Alive (KCA), a non-profit organization dedicated to saving young lives affected by HIV/AIDS. Beyond financial support, our collaboration reflects a shared commitment to driving profound, positive change in the lives of vulnerable children and families. Through our partnership with KCA, we wholeheartedly support essential healthcare, secure housing, and the power of education and self-sufficiency. We believe these things are vital to breaking the cycle of adversity and offering a brighter future. Join us on this journey to make a real impact. Skylab, where purpose meets passion, is making a difference that truly matters.'
            heading2=''
            description2=''
            heading3=''
            description3=''
        /> 
    </div>

    <div>
        <Footers/>
    </div>
    <a href='#TopScroll'><div id='Scroll-to-Top'>
        <MDBIcon fas icon="angle-double-up" />
      </div>
      </a>
      
    </>
  )
}
// http://meraj.mypressonline.com/
// http://meraj.mypressonline.com/
import React from 'react'
import NavbarTopText from './About/NavbarTopText'
import SocialMedia from './Home1/SocialMedia'
import Footers from './Navbar&Footer/Footer'
import { MDBBtn, MDBContainer, MDBIcon } from 'mdb-react-ui-kit'
import { Link } from 'react-router-dom'
import PrivacyDropDown from './Privacy/PrivacyDropDown'
import MegaNavbars from './Navbar&Footer/MegaNavbars'
import HeadingAnimation from './Animations/HeadingAnimation'


export default function Privacy() {
  return (
    <>
      {/* <div>
        <Navbars/>
      </div> */}
      <div>
        <MegaNavbars/>
      </div>
      <div id='TopScroll'>
        <NavbarTopText heading='PRIVACY POLICY' discription='' pagename=''/>
      </div>
      <div>
                 <div className='py-5 text-center'>
                    <p className='fs-1  text-black' id="TTFonts"><HeadingAnimation text='Last updated'/></p>
                    <hr class="hr-text" data-content="★"/>
                    <MDBContainer>
                    <p id='DancingFonts'><b> August  25, 2023 </b><br/>
                        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
                        We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
                    </p>
                    </MDBContainer>
               </div>
      </div>

      {/* <div>
        <GridIcons/>
      </div> */}

      <div>
                {/* <div className='py-5 text-center'>
                    <p className='fs-1  text-black' id="TTFonts">Frequently asked Question !</p>
                    <hr class="hr-text" data-content="★"/>
                </div> */}
        <PrivacyDropDown/>
      </div>
      <div>
        <SocialMedia/>
      </div>
      <div className='text-center pb-5'>
            <Link to='/contact'><MDBBtn className='bg-black text-light' color='black' onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>Contact Now!</MDBBtn></Link>
      </div>
      <div>
        <Footers/>
      </div>
      <a href='#TopScroll'><div id='Scroll-to-Top'>
        <MDBIcon fas icon="angle-double-up" />
      </div>
      </a>
      
    </>
  )
}
// http://meraj.mypressonline.com/
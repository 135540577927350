import { MDBIcon } from 'mdb-react-ui-kit';
import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';

function Video({ videoUrl }) {
  const [isPlaying, setIsPlaying] = useState(true);
  const playerRef = useRef();

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleTogglePlay = () => {
    setIsPlaying(!isPlaying);
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.getInternalPlayer().pause();
      } else {
        playerRef.current.getInternalPlayer().play();
      }
    }
  };

  return (
    <div className="fullscreen-video`}" style={{ position: 'relative', zIndex: '-1' }}>
      <ReactPlayer
        ref={playerRef}
        url={videoUrl} // Use the passed video URL
        controls={false}
        width="100%"
        height="100%"
        muted={true}
        playing={isPlaying}
        loop={true} // Add loop prop for continuous playback
        onPlay={handlePlay}
        onPause={handlePause}
      />

      {!isPlaying && (
        <span id='knowledge-center-play-icon'
         onClick={handleTogglePlay}>
          <MDBIcon fas icon="play-circle" />
        </span>
      )}
    </div>
  );
}

export default Video;



import React from 'react'
import NavbarTopText from './About/NavbarTopText'
import CompanyAbout from './About/CompanyAbout'
import HistoryRightImage from './About/HistoryRightImage'
import HistoryLeftImage from './About/HistoryLeftImage'
import Footers from './Navbar&Footer/Footer'
import MegaNavbars from './Navbar&Footer/MegaNavbars'
import { MDBIcon } from 'mdb-react-ui-kit'


export default function About() {
  return (
    <>
      {/* <div>
        <Navbars/>
      </div> */}
      <div>
        <MegaNavbars/>
      </div>
      <div id='TopScroll'>
        <NavbarTopText heading='ABOUT SKYLAB' discription='' pagename=''/>
      </div>
      <div>
        <CompanyAbout/> 
      </div>
      <div>
            <div className='pb-5 text-center'>
                <p id='TTFonts' className='fs-1'>The beginning of a lasting tradition</p>
                <hr class="hr-text" data-content="★"/>
            </div>
            <HistoryRightImage 
                heading='SKYLABʼS COMMITMENT'
                title= "Your Trusted Partner, Not Just a Vendor."
                discription='We are dedicated to being the best partner, delivering top-notch service and consistently pushing the boundaries of what we offer. Our goal is to promote, educate, and facilitate more stone sales. We understand your unique business needs and are committed to delivering exceptional service and quality products to help you succeed.'
                images='OurCommitment.jpg' 
                btnLink='/skylabcommitment'
                
            />
      </div>
      <div>
        <HistoryLeftImage 
            heading='SKYLABʼS PROCESS OF BRILLIANCE'
            discription='Skylab Diamond crafts brilliance from the top 10% of rough slabs, shaping each with precision and subjecting it to rigorous inspections and helium tests. Our ideal stone maps, honed for angles and faceting, guarantee unmatched perfection. With a growth from 12 to over 500 craftsmen, our legacy lies in this shared craftsmanship, ensuring excellence in every diamond we produce.'
            images='PROCESSOFBRILLIANCE2.jpg'
            btnLink='/skylabProcessOfBrilliance'

        />
      </div>
    
      <div>
        <Footers/>
      </div>
      <a href='#TopScroll'><div id='Scroll-to-Top'>
        <MDBIcon fas icon="angle-double-up" />
      </div>
      </a>
    </>
  )
}

// http://meraj.mypressonline.com/
// ʼ ʹ  ‛  
// http://meraj.mypressonline.com/
import React, { useState, useEffect } from "react";
import NavbarTopText from "./About/NavbarTopText";
import TotalProduct from "./Product/TotalProduct";
import Video from "./Home1/Video";
import ProductGridLeft from "./Product/ProductGridLeft";
import ProductGridRight from "./Product/ProductGridRight";
import Footers from "./Navbar&Footer/Footer";
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import MegaNavbars from "./Navbar&Footer/MegaNavbars";
import HeadingAnimation from "./Animations/HeadingAnimation";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";
import Carousel from "react-spring-3d-carousel";

export default function Product() {
  const UrlData1 = "loose diamonds - Asscher.gif";
  const UrlData5 = "loose diamonds -Cushion.gif";
  const UrlData6 = "loose diamonds - marquise.gif";
  const UrlData7 = "loose diamonds - oval.gif";
  const UrlData8 = "loose diamonds - pear.gif";
  const UrlData9 = "loose diamonds - princess.gif";
  const UrlData10 = "loose diamonds - radiant.gif";
  const UrlData11 = "loose diamonds - round.gif";

  // const UrlData2 = "https://lgdus.co/skylab_shape/ColorDiamond.mp4";
  const UrlData2 = "ColorDiamond.mp4";
  const UrlData3 = "DiamondJewelery.gif";
  const UrlData4 = "LayoutDiamond.mp4";
  // const UrlData4 = "https://lgdus.co/skylab_shape/LayoutDiamond.mp4";

  const [isReadMore, setIsReadMore] = useState(false);
  const [goToSlide, setGoToSlide] = useState(0);
  const [offsetRadius] = useState(2);
  const [configState] = useState(config.gentle);

  // Slides definition (no click functionality)
  const slides = [
    {
      id: 1,
      image: "LosseDiamond.png",
      text: "Loose Diamonds",
      reDirectLink: "#looseDiamond",
    },
    {
      id: 2,
      image: "jewelry_product_page.png",
      text: "Diamond Jewelry",
      reDirectLink: "#diamondjewelery",
    },
    {
      id: 3,
      image: "MIX_DIAMOND FINAL.1000.png",
      text: "Fancy Color Diamonds",
      reDirectLink: "#coloDiamond",
    },
    {
      id: 4,
      image: "product-Layout.jpg",
      text: "Layout Diamonds",
      reDirectLink: "#layoutDiamond",
    },
  ];

  const slideElements = slides.map((slide, index) => ({
    key: uuidv4(),
    content: (
      <div className="text-center" id="atYourService">
        <img
          src={slide.image}
          alt={slide.text}
          className="p-4 mt-5"
          style={{
            width: goToSlide === index ? "380px" : "250px", // Larger size for active, smaller for inactive
            height: goToSlide === index ? "380px" : "250px", // Adjust height accordingly
            transition: "all 1.5s ease-in-out", // Smooth transition between sizes
          }}
        />
        <p id="TTFonts" className="text-center fs-5">{slide.text}</p>
      </div>
    ),
  }));

  useEffect(() => {
    const interval = setInterval(() => {
      setGoToSlide((prev) => (prev + 1) % slides.length);
    }, 3000); // Slide changes every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [slides.length]);


  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      {/* <div>
        <Navbars/>
      </div> */}
      <div>
        <MegaNavbars />
      </div>

      <div id="TopScroll" className="mb-10">
        <NavbarTopText heading="SKYLAB PRODUCTS" discription="" pagename="" />
      </div>
      <div>{/* <TotalProduct /> */}</div>

      <div className="mt-5 mb-14 t-100">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="5" lg="6" data-aos="fade-up">
              {/* <img src="OurCommitment.jpg" alt="" className="w-100"   loading="lazy"/> */}
              <Carousel
                 slides={slideElements}
                 goToSlide={goToSlide}
                 offsetRadius={offsetRadius}
                 animationConfig={configState}
              />
            </MDBCol>
            <MDBCol
              md="7"
              lg="6"
              className="d-flex justify-content-center align-items-center "
              data-aos="fade-up"
            >
              <div className="container " id="OurPhiloshpy-text-box">
                <p
                  className="fs-1 text-center mt-4"
                  style={{ position: "relative", left: "50px" }}
                >
                  <HeadingAnimation text="OUR PRODUCTS" />
                </p>
                <p id="DancingFonts" className="text-center fs-5 parag-width ">
                  <span className="fw-bold " id="DancingFonts">
                    Skylab Diamond: Elevating Your Inventory, Delivering
                    Exceptional Value.
                  </span>{" "}
                  <br />
                  An unwavering desire to create the most beautiful jewelry.
                  This starts with manufacturing every stone in house, from 0.01
                  ct to 20 ct, using the same techniques and quality control to
                  manufacture smaller goods as we do larger certified diamonds.
                  From this
                  <span style={{ display: isReadMore ? "none" : "inline" }}>
                    ...
                  </span>{" "}
                  <span
                    style={{ display: isReadMore ? "inline" : "none" }}
                    id="DancingFonts"
                  >
                    {" "}
                    we are redrafting the boundaries of jewelry production to
                    bring to life the beauty of lab grown diamonds. We have
                    built an entire team to push the limits of what is possible
                    – retraining an entire labor force to focus on finesse and
                    higher quality workmanship.
                    <p>
                      {" "}
                      You need to see it to believe it! The classics reworked –
                      Bracelets, Necklaces, Hoops, and Eternity Bands! Bespoke
                      creations – Bezel Set, Multi-Stone, Fancy Color and More!
                      We are working tirelessly to bring to life any product
                      that our clients can imagine!
                    </p>
                  </span>
                  <span
                    className="text-black fw-bold fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={toggleReadMore}
                  >
                    {isReadMore ? "Read less" : "Read more"}
                  </span>
                </p>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      {/* --------------------------------------------------Loose Diamond------------------------------------ */}
      <div>
        <div className="pt-5 text-center">
          <span className="fs-1 text-black" id="TTFonts">
            <HeadingAnimation text="Loose Diamonds" />
          </span>
          <p id="DancingFonts" className="d-flex justify-content-center" >
            <div id="looseDiamond" className="w-75 text-center">
            Embark on a journey of possibilities with our best-in-class colorless diamonds. We work with only the highest quality of rough material, rejecting over 95% of lab grown diamond rough. Each stone is cut to provide the most allure and maximum brilliance. View our incredible collection of unique shapes within our inventory.
            </div>
          </p>
        </div>

        <div className="row justify-content-center pt-3" data-aos="fade-up">
          <div className="diamond-gallery  no-scroll-gallery">
            <img src={UrlData1} className="diamond-image" loading="lazy" />
            <img src={UrlData5} className="diamond-image" loading="lazy" />
            <img src={UrlData6} className="diamond-image" loading="lazy" />
            <img src={UrlData7} className="diamond-image" loading="lazy" />
            <img src={UrlData8} className="diamond-image" loading="lazy" />
            <img src={UrlData9} className="diamond-image" loading="lazy" />
            <img src={UrlData10} className="diamond-image" loading="lazy" />
            <img src={UrlData11} className="diamond-image" loading="lazy" />
          </div>
        </div>


 {/* --------------------------------------------------Jewelery------------------------------------ */}
 <div>
        <div className="pt-5 text-center">
          <span className="fs-1  text-black" id="TTFonts">
            <HeadingAnimation text="Diamond Jewelry" />
          </span>
          <p id="DancingFonts">
            <span id="diamondjewelery">
              Enhance your inventory with our exquisite diamond jewelry. We are
              rewriting what is possible, offering you high-end jewelry that is
              in stock as well as customizable with ease.
            </span>
          </p>
        </div>
        <span data-aos="fade-up" className="jewelery-gif">
          <ProductGridRight
            videos={<img src={UrlData3} loading="lazy" className="jwelery-fix" />}
            regular1="ProductJewelery1.jpg"
            hover1="ProductJewelery2.jpg"
            regular2="product-jewelry 2.png"
            hover2="product-jewelry 2.1.png"
            regular3="product-jewelry 3.png"
            hover3="product-jewelry 3.1.png"
            regular4="product-jewelry4.png"
            hover4="product-jewelryy4.1.png"
          />
        </span>
      </div>

        {/* --------------------------------------------------Color Diamond------------------------------------ */}
        <div>
          <div className="pt-5 text-center">
            <span className="fs-1  text-black" id="TTFonts">
              <HeadingAnimation text="Fancy Color Diamonds" />
            </span>
            <p id="DancingFonts">
              <span id="coloDiamond">
                Elevate your offerings with never seen before fancy-colored
                diamonds and jewelry. A large variety of shapes and sizes for
                every desire.
              </span>
            </p>
          </div>
          <span data-aos="fade-up">
            <ProductGridRight
              videos={<Video videoUrl={UrlData2} loading="lazy" autoplay loop/>}
              regular1="colour-diamond1.jpg"
              hover1="colour-diamond1.1.jpg"
              regular2="colour diamonds 2.png"
              hover2="colour diamonds 2.1.png"
              regular3="colour diamonds 3.jpg"
              hover3="colour diamonds 3.1.jpg"
              regular4="colour diamonds 4.jpg"
              hover4="colour diamonds 4.1.jpg"
            />
          </span>
        </div>
      </div>

      {/* --------------------------------------------------Layout Diamond------------------------------------ */}
      <div>
        <div className="pt-5 text-center">
          <span className="fs-1  text-black" id="TTFonts">
            <HeadingAnimation text="Layout Diamonds" />
          </span>
          <p id="DancingFonts">
            <span id="layoutDiamond">
            Refine your collection with curated diamond layouts, perfectly cut and perfectly matched.
            </span>
          </p>
        </div>
        <span data-aos="fade-up">
          <ProductGridLeft
            videos={<Video videoUrl={UrlData4} loading="lazy" autoplay loop />}
            regular1="product-layout 1.png"
            // hover1="product layout 1.1.png "
            regular2="product-layout 2.png"
            // hover2="LoosDiamond4.jpg"
            regular3="product-layout 3.1.jpg"
            // hover3="product_layout_3.jpg"
            regular4="product layout 4.jpg"
            // hover4="product layout 4.1.jpg"
          />
        </span>
      </div>

     
      <div>
        <Footers />
      </div>
      <a href="#TopScroll">
        <div id="Scroll-to-Top">
          <MDBIcon fas icon="angle-double-up" />
        </div>
      </a>
    </>
  );
}
// http://meraj.mypressonline.com/

import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'

export default function School() {
   
  return (
      <MDBContainer  className='text-center'>
            <MDBRow className='py-5'>
                <MDBCol lg='6' md='auto' className='d-flex justify-content-center align-items-center' data-aos="fade-up">
                    <div className='container' id='OurPhiloshpy-text-box'>
                        <p id='DancingFonts' className='text-start'>Through our Corporate Social Responsibility initiatives, we actively support schools and educational programs. Our commitment extends to</p>
                        <p className='fs-2 ' id='TTFonts' style={{textAlign:"left", color:"black"}}>Infrastructure Development</p>
                        <p id='DancingFonts' className='text-start'>We invest in building and renovating school infrastructure in underserved communities. We believe that a safe and conducive learning environment is essential for student success.</p>
                    </div>  
                </MDBCol>
                <MDBCol lg='6' md='12' data-aos="fade-up">
                    <div><img src='schoolImage-1.png' alt=""  className='img-fluid'/></div>
                </MDBCol>
            </MDBRow>

            <MDBRow  className='py-5'>
                <MDBCol lg='6' md='12' data-aos="fade-up">
                <div><img src='Foundation_02.jpg' alt=""  className='img-fluid'/></div>
                </MDBCol>
                <MDBCol lg='6' md='auto' className='d-flex justify-content-center align-items-center' data-aos="fade-up">
                    <div className='container'>
                         <span className='fs-2 title-school'  id='TTFonts'>STEM Education</span>
                         <p id='DancingFonts' className='text-start'>As a lab-grown diamond company, we have a natural affinity for science and technology. We support STEM (Science, Technology, Engineering, and Mathematics) education initiatives to inspire the next generation of innovators.</p>
                         <span className='fs-2  title-school-long'   id='TTFonts' >The Radiance of Our Contribution</span>
                         <p id='DancingFonts' className='text-start '>Here are some ways in which Skylab has already made a significant impact on this school</p>
                     </div>   
                </MDBCol>
            </MDBRow>
           
      </MDBContainer>
  
  )
}

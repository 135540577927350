import React from 'react'
import LearnMore from './LearnMore'
import Navbars from '../Navbar&Footer/Navbars'
import NavbarTopText from './NavbarTopText'
import Footers from '../Navbar&Footer/Footer'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import MegaNavbars from '../Navbar&Footer/MegaNavbars'
import HeadingAnimation from '../Animations/HeadingAnimation'

export default function SkylabCommitment() {
    
  return (
    <>
    {/* <div>
        <Navbars />
    </div> */}
    <div>
        <MegaNavbars/>
    </div>
    <div>
        <NavbarTopText heading='THE COMMITMENT' discription='' pagename=''/>
    </div>
         <div>
            <MDBContainer>
                <MDBRow>
                    <MDBCol lg='6' md='6' className='my-5 py-5' data-aos="fade-up">
                        <div className='bg-image hover-zoom' style={{cursor:'pointer'}}>
                            <img src='OurCommitment.jpg' alt=""  className='w-100'  loading="lazy"/>
                        </div>
                    </MDBCol>
                    <MDBCol lg='6' md='6' className='d-flex justify-content-center align-items-center' data-aos="fade-up">
                        <div className='container' id='OurPhiloshpy-text-box'>
                            <p className='fs-1 text-center '><HeadingAnimation text="SKYLABʼS COMMITMENT"/></p>
                         
                            <p id='DancingFonts' className='text-center fs-5'><b>Your Trusted Partner, Not Just a Vendor.</b><br />We are dedicated to being the best partner, delivering top-notch service and 
consistently pushing the boundaries of what we offer. Our goal is to promote, 
educate, and facilitate more stone sales. We understand your unique business 
needs and are committed to delivering exceptional service and quality products to 
help you succeed. 
</p>
                        </div>  
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
        <div>
            <LearnMore 
            heading1='Finest Materials' 
            discription1='The raw material we work with takes longer to grow and requires more intense quality controls than run of the mill products. We reject almost 90% of the lab grown material available in the market, working only with the purest crystals.' 
            images1='FinestMaterials2.jpg' 
            images2='Exceptional.jpg' 
            heading2='Exceptional Craftsmanship' 
            discription2='Skylab leans on the expertise of its founders, continually working to improve the visual aesthetic and performance by redrawing ideal stone maps. These maps serve as a guide for our skilled craftsmen, ensuring precise cuts and perfected shapes that bring to life the fire and brilliance in every diamond. '
            />
        </div>
        
        <div>
            <Footers/>
        </div>
    </>
  )
}

import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

export default function Footers() {
  return (
    <div className='bg-black'>
    <MDBContainer className='bg-light'>
    <MDBFooter bgColor='black' className='text-center text-lg-start text-muted pt-2'>
      <section className='text-white'>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                {/* <MDBIcon color='light' icon='spa' className='me-3' /> */}
                <img src="footerLogo.png" alt="" className='p-1' id='zoom-loop-animation' width="20%"/>
                Skylab Diamond Inc.
              </h6>
              <p>
              Get the Best, Get Skylab
              </p>
            </MDBCol>

            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
              <p>
                <Link to='/product' className='text-reset' onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>
                Product
                </Link>
              </p>              
              <p>
                <Link to='https://eshop.skylabdiamond.com' className='text-reset' onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>
                  Inventory
                </Link>
              </p>
              
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4 text-white'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <Link to='/' className='text-reset' onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>
                  Home
                </Link>
              </p>
              <p>
                <Link to='/about' className='text-reset' onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>
                  About Us
                </Link>
              </p>
              <p>
                <Link to='/foundation' className='text-reset' onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>
                  Foundation
                </Link>
              </p>
              <p>
                <Link to='/contact' className='text-reset' onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>
                  Contact
                </Link>
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon color='light' icon='home' className='me-2' />
                <a href='https://goo.gl/maps/6fEsbUnVo3obZVUg9' className='text-light text-decoration-none'>SkyLab Diamond Inc. 580 5th Avenue,Suite 1002, New York, NY 10036</a>
              </p>
              <p>
                <MDBIcon color='light' icon='envelope' className='me-3' />
                <a href='mailto:info@skylabdiamond.com' className='text-light text-decoration-none'>info@skylabdiamond.com</a>
              </p>
              <p>
                <MDBIcon color='light' icon='phone' className='me-3' /> 
                <a href='tel:+16468924262' className='text-light text-decoration-none'>+1 (646) 892 4262</a>
              </p>
              
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4 text-white border-top'>
        © 2021 Copyright: &nbsp;
        <a className='text-reset ' href='#'>
           Skylab Diamond
        </a>
      </div>
    </MDBFooter>
    </MDBContainer>
    </div>
  );
}
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import { Link } from "react-router-dom";
import HeadingAnimation from "../Animations/HeadingAnimation";

export default function CompanyAbout() {
  return (
    <MDBContainer fluid className="text-center my-5 py-5">
      <MDBRow>
        <MDBCol md="5" lg="6" className="d-flex justify-items-center align-items-center">
          <div
            className="bg-image hover-zoom  p-5"
            style={{ cursor: "pointer" }}
          >
            <img
              src="ourStoryFrame.jpg"
              alt="dd"
              id="skylabStory"
              className="w-100 h-100"
              loading="lazy"
            />
          </div>
        </MDBCol>
        <MDBCol md="7" lg="6">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div id="skylabStoryTextBox" className="text-center">
              <div className="pt-3 d-flex justify-content-center align-content-center text-center title-skylab">
                <HeadingAnimation text="SKYLAB'S STORY" />
              </div>
              <p id="DancingFonts" className=" fs-5 moreaboutskylab" style={{width:"96%"}}>
                We pride ourselves on offering the world's best lab-grown
                diamonds. Our journey began in 2013, with a deep understanding
                of lab-grown technology and a commitment to excellence. We
                meticulously select the purest seeds and crystals, ensuring only
                the highest quality materials are used in our manufacturing
                process. We manufacture 100% of our inventory in-house,
                guaranteeing top-notch craftsmanship every step of the way.
              </p>
              <Link
                to="/moreskylabstory"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                <button
                  className="hoverButtonClassname"
                  id="hoverButton"
                  style={{ position: "relative" }}
                >
                  <span id="hoverButtonSpan">LEARN MORE</span>
                </button>
              </Link>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

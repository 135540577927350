import React, { useEffect, useState } from 'react';

function HeadingAnimation({ text }) {
  const [revealed, setRevealed] = useState(false);

  useEffect(() => {
    function revealSpoiler() {
      const element = document.getElementById(text);
      if (element && !revealed && isElementInViewport(element)) {
        element.style.color = '#000';
        element.style.backgroundSize = '0% 100%';
        setRevealed(true);
      }
    }

    window.addEventListener('scroll', revealSpoiler);

    return () => {
      window.removeEventListener('scroll', revealSpoiler);
    };
  }, [revealed, text]);

  function isElementInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  return (
    <span id='HeadingText-Animation'><span id={text} className="spoiler" style={{ color: 'transparent', backgroundPosition: 'right', backgroundSize: '100% 100%', backgroundImage: 'linear-gradient(black, black)', display: 'inline', backgroundRepeat: 'no-repeat', transition: 'all 500ms ease-in-out', fontFamily: 'Oswald, sans-serif', textTransform: 'uppercase', cursor: 'pointer' }}>{text}</span></span>
  );
}

export default HeadingAnimation;

import React from "react";
import LearnMore from "./LearnMore";
import Navbars from "../Navbar&Footer/Navbars";
import NavbarTopText from "./NavbarTopText";
import Footers from "../Navbar&Footer/Footer";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import MegaNavbars from "../Navbar&Footer/MegaNavbars";
import HeadingAnimation from "../Animations/HeadingAnimation";

export default function MoreSkylabStory() {
  return (
    <>
      {/* <div>
        <Navbars />
    </div> */}
      <div>
        <MegaNavbars />
      </div>
      <div>
        <NavbarTopText heading="THE STORY" discription="" pagename="" />
      </div>
      <div>
        <MDBContainer className="py-5">
          <MDBRow>
            <MDBCol lg="6" md="6" xl="6" size="12" sm="12" xs="12">
              <div
                className="bg-image hover-zoom"
                style={{ cursor: "pointer" }}
              >
                <img
                  src="ourStoryFrame.jpg"
                  alt=""
                  className="w-100 h-100"
                  loading="lazy"
                />
              </div>
            </MDBCol>
            <MDBCol
              lg="6"
              md="6"
              xl="6"
              size="12"
              sm="12"
              xs="12"
              className="d-flex justify-content-center align-items-center "
            >
              <div className="container" id="OurPhiloshpy-text-box">
                <p className="fs-1 text-center">
                  <HeadingAnimation text="SKYLAB'S STORY" />
                </p>
                <p
                  id="DancingFonts"
                  className="text-center  fs-5"
                  style={{ width: "96%" }}
                >
                  We pride ourselves on offering the world's best lab-grown
                  diamonds. Our journey began in 2013, with a deep understanding
                  of lab-grown technology and a commitment to excellence. We
                  meticulously select the purest seeds and crystals, ensuring
                  only the highest quality materials are used in our
                  manufacturing process. We manufacture 100% of our inventory
                  in-house, guaranteeing top-notch craftsmanship every step of
                  the way.
                </p>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      {/* <div>
            <LearnMore 
            heading1='Founding Visionaries' 
            discription1='Skylab Diamond, established by the visionary trio of Dinesh Goti, Hiren Goti, and Sagar Mehta, embodies their lifelong devotion to diamonds. Together, our collective passion and deep understanding form the essence of Skylab Diamonds commitment to innovation and excellence.' 
            images1='FoundingVisionaries.jpg' 
            images2='HandsOnJourney.png' 
            heading2='Hands-On Journey' 
            discription2='From the age of 12, Dinesh Goti and Hiren Goti immersed themselves in diamond cutting and polishing, accumulating over 25 years of hands-on experience in every facet of the industry. Notably, Sagar Mehtas family background in the natural diamond industry adds a profound layer of knowledge stemming from his fathers extensive experience in the field.'
            />
        </div> */}
      <div>
        <LearnMore
          heading1="Founding Visionaries"
          discription1="Skylab Diamond, established by the visionary trio of Dinesh Goti, Hiren Goti, and Sagar Mehta, embodies their lifelong devotion to diamonds. Hiren and Dinesh Goti, they bring with them years of actual physical cutting experience and knowledge. We incorporate this knowledge into continually improving our diamonds for better performance, brilliance, fire and scintillation."
          images1="FoundingVisionaries.jpg"
          images2="about-story-Crafting.png"
          heading2="Crafting Excellence Together"
          discription2="Skylab - your premier destination for top-quality lab-grown 
                            diamonds and stunning jewelry. We're more than just a diamond company; 
                            we're here to be the best partner possible in this space, consistently providing 
                            the best diamonds and unparalleled service worldwide."
        />
      </div>
      {/* <div>
            <LearnMore 
            heading1='Founding Visionaries' 
            discription1='Skylab Diamond, established by the visionary trio of Dinesh Goti, Hiren Goti, and Sagar Mehta, embodies their lifelong devotion to diamonds. Hiren and Dinesh Goti, they bring with them years of actual physical cutting experience and knowledge. We incorporate this knowledge into continually improving our diamonds for better performance, brilliance, fire and scintillation.' 
            images1='FoundingVisionaries.jpg' 
            images2='about-story-Crafting.png' 
            heading2='Crafting Excellence Together' 
            discription2="Skylab - your premier destination for top-quality lab-grown 
                            diamonds and stunning jewelry. We're more than just a diamond company; 
                            we're here to be the best partner possible in this space, consistently providing 
                            the best diamonds and unparalleled service worldwide."
            />
        </div> 
         */}
      {/* <div>
        <LearnMore 
            heading1='Innovation and Learning' 
            discription1='This extensive journey serves as the bedrock for our claim to producing the finest diamonds, rooted in our comprehensive understanding of the manufacturing process. Our commitment to continual learning fuels a fusion of traditional knowledge and cutting-edge technology.' 
            images1='Innovation.jpg' 
            images2='about-story-Crafting.png' 
            heading2='Crafting Excellence Together' 
            discription2='Expertise meets innovation as Skylab creates ideal stone maps, based on angles, faceting, and cut to achieve the brightest diamonds. These maps, frozen with modern tech, set a stringent standard, ensuring each Skylab Diamond reflects brilliance and perfection.'
            />
        </div> */}
      <div>
        <Footers />
      </div>
    </>
  );
}

import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Addresses() {
  return (
   <div className='mar-left'>
        <MDBRow>
        <MDBCol lg='12' md='3' xs='3' className='p-2 text-center'>
    <div className='text-center d-flex align-items-start'>
        <Link to='https://maps.app.goo.gl/ZMTrufqQ4n9WpBzs6'>
            <div className='py-3 px-4 text-black' color='black'>
                <span>
                    <i className="fa-solid fa-location-dot font-size"></i>
                </span>
            </div>
        </Link>
        <div className='pt-2 px-2 text-start'>
            <p><b>Address:</b></p>
            <p className='m-0'>
                SkyLab Diamond Inc.<br />
                580 5th Avenue, Suite 1002,<br />
                New York, NY 10036
            </p>
        </div>
    </div>
</MDBCol>
            
<MDBCol lg='12' md='3' xs='3' className='p-2'>
    <div className='d-flex align-items-start'>
        <a href="tel:+16468924262">
            <div className='py-3 px-4 text-black' color='black'>
                <span>
                    <i className="fa-solid fa-phone font-size"></i>
                </span>
            </div>
        </a>
        <div className='pt-3 px-2 text-start'>
            <p className='m-0'>
                <b>Phone:</b>
                +1 (646) 892 4262
            </p>
        </div>
    </div>
</MDBCol>    
<MDBCol lg='12' md='3' xs='3' className='p-2'>
    <div className='d-flex align-items-start'>
        <a href="mailto:info@skylabdiamond.com">
            <div className='py-3 px-4 text-black' color='black'>
                <span id='zoom-loop-animation'>
                    <i className="fa-solid fa-envelope font-size"></i>
                </span>
            </div>
        </a>
        <div className='pt-2 px-2 text-start'>
            <p className='m-0'>
                <b>Email:</b> <br /> info@skylabdiamond.com
            </p>
        </div>
    </div>
</MDBCol>

            
            {/* <MDBCol lg='12' md='3' xs='3' className='p-3'>
                <div className='text-center'>
                    <Link to='https://skylabdiamond.com/'><MDBBtn className='py-3 px-4 rounded-circle bg-black text-light' color='black'>
                        <span id='zoom-loop-animation'><MDBIcon fas icon="external-link-alt" /></span>
                    </MDBBtn></Link>
                    <p className='pt-2'><b>Website : </b>https://skylabdiamond.com/</p>
                </div>
            </MDBCol> */}
           
        </MDBRow>
   </div>
  )
}

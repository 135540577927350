import React from 'react'
import { Link } from 'react-router-dom'
// http://meraj.mypressonline.com/
export default function Erorr() {
  return (
    <div className='d-flex justify-content-center align-items-center h-100'>

        <Link to='/'><img src="https://freefrontend.com/assets/img/html-funny-404-pages/CodePen-404-Page.gif" alt=""  className='w-100'  loading="lazy"/></Link>
  
    </div>
  )
}

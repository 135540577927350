import React from 'react';
import { MDBAccordion, MDBAccordionItem, MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import HeadingAnimation from '../Animations/HeadingAnimation'


export default function PrivacyDropDown() {
  // initialActive={1}
  return (
    <MDBContainer>
        <MDBAccordion borderless>
      
      <MDBAccordionItem collapseId={1} headerTitle={<><MDBIcon fas icon="angle-double-right" /> &nbsp; Interpretation And Definitions</>}>
        <h4><HeadingAnimation text='Interpretation'/></h4>
        
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.</p>
        <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
        <h4><HeadingAnimation text='Definitions'/></h4>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
            <li>You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Service.</li>
            <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Skylab Diamonds, 580 5th Avenue,Suite 1002, New York, NY 10036.For the purpose of the GDPR, the Company is the Data Controller.</li>
            <li>Application means the software program provided by the Company downloaded by You on any electronic device, named Skylab Diamonds</li>
            <li>Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
            <li>Account means a unique account created for You to access our Service or parts of our Service.</li>
            <li>Website refers to Skylab Diamonds, accessible from www.skylabdiamond.com</li>
            <li>Service refers to the Application or the Website or both.</li>
            <li>Country refers to: New York, United States</li>
            <li>Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.For the purpose of the GDPR, Service Providers are considered Data Processors.</li>
            <li>Personal Data is any information that relates to an identified or identifiable individual.For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.</li>
            <li>Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses</li>
            <li>Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
            <li>Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
            <li>Data Controller, for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.</li>
            <li>Do Not Track (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.</li>
        </ul>  
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={2} headerTitle={<><MDBIcon fas icon="angle-double-right" /> &nbsp; Collecting And Using Your Personal Data</>}>
            <p className='fs-3'><HeadingAnimation text='Types Of Data Collected'/></p>
            <p className='fs-4 fw-bold'><HeadingAnimation text='Personal Data'/></p>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>Usage Data</li>
            </ul>
            <p className='fs-5 fw-bold'><HeadingAnimation text='Usage Data'/></p>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
            <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
            <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
            
            <p className='fs-5 fw-bold'><HeadingAnimation text='Information Collected While Using The Application'/></p>
            <p>While using Our Application, in order to provide features of Our Application, We may collect, with your prior permission:</p>
            <p>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it be simply stored on Your device.</p>
            <p>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it be simply stored on Your device.</p>

            <p className='fs-5 fw-bold'><HeadingAnimation text='Tracking Technologies and Cookies'/></p>
            <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.</p>
            <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser. Learn more about cookies here: Cookies: What Do They Do?</p>
            <p>We use both session and persistent Cookies for the purposes set out below:</p>

            <ul>
                <li>Necessary / Essential Cookies</li>
                <li>Type: Session Cookies</li>
                <li>Administered by: Us</li>
                <li>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</li>
                <p className='fs-5 fw-bold'><HeadingAnimation text='Cookies Policy / Notice Acceptance Cookies'/></p>
                <li>Type: Persistent Cookies</li>
                <li>Administered by: Us</li>
                <li>Purpose: These Cookies identify if users have accepted the use of cookies on the Website</li>
                <li>Functionality Cookies</li>
                <p className='fs-5 fw-bold'><HeadingAnimation text='Type: Persistent Cookies'/></p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
                <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.</p>

                <p className='fs-4 fw-bold mt-4'><HeadingAnimation text='Use Of Your Personal Data'/></p>
                <p>The Company may use Personal Data for the following purposes:</p>
                <ul>
                  <li><b>To provide and maintain our Service</b> including to monitor the usage of our Service.</li>
                  <li><b>To manage Your Account:</b> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
                  <li><b>For the performance of a contract:</b> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
                  <li><b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
                  <li>To manage Your requests: To attend and manage Your requests to Us.</li>
                  <p>We may share your personal information in the following situations:</p>
                  <li><b>With Service Providers:</b> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to show advertisements to You to help support and maintain Our Service, to contact You, to advertise on third party websites to You after You visited our Service or for payment processing.</li>
                  <li><b>For Business transfers:</b> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                  <li><b>With Affiliates:</b> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
                  <li><b>With Business partners:</b> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
                  <li><b>With other users:</b> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>              
                </ul>
                <p className='fs-2'><HeadingAnimation text='Retention Of Your Personal Data'/></p>
                <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>        
                <p className='fs-3'><HeadingAnimation text='Transfer Of Your Personal Data'/></p>
                <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
            </ul>
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={3} headerTitle={<><MDBIcon fas icon="angle-double-right" /> &nbsp; Disclosure Of Your Personal Data</>}>
                <p className='fs-3 Benne-font fw-bold'><HeadingAnimation text='Business Transactions'/></p>
                <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                <p className='fs-3 Benne-font fw-bold'><HeadingAnimation text='Law Enforcement'/></p>
                <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                <p className='fs-3 Benne-font fw-bold'><HeadingAnimation text='Other Legal Requirements'/></p>
                <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                
                <ul>
                   <li>Comply with a legal obligation</li>
                   <li>Protect and defend the rights or property of the Company</li>
                   <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                   <li>Protect the personal safety of Users of the Service or the public</li>
                   <li>Protect against legal liability</li>
                </ul>
                <p className='fs-4 fw-bold'><HeadingAnimation text='Security Of Your Personal Data'/></p>
                <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                <p className='fs-4 fw-bold'><HeadingAnimation text='Detailed Information On The Processing Of Your Personal Data'/></p>
                <p>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.</p>
            
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={4} headerTitle={<><MDBIcon fas icon="angle-double-right" /> &nbsp; GDPR Privacy</>}>
            <p className='fs-2 Benne-font'><HeadingAnimation text='Legal Basis For Processing Personal Data Under GDPR'/></p>
            <p>We may process Personal Data under the following conditions:</p>
            <ul>
                <li>Consent: You have given Your consent for processing Personal Data for one or more specific purposes.</li>
                <li>Performance of a contract: Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.</li>
                <li>Legal obligations: Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.</li>
                <li>Vital interests: Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.</li>
                <li>Public interests: Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company</li>
                <li>Legitimate interests: Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.</li>
            </ul>
            <p>In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>
            <p className='fs-4 fw-bold'><HeadingAnimation text='Your Rights Under The GDPR'/></p>
            <p>The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.</p>
            <p>You have the right under this Privacy Policy, and by law if You are within the EU, to</p>
            <ul>
                <li>Request access to Your Personal Data. The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.</li>
                <li>Request correction of the Personal Data that We hold about You. You have the right to to have any incomplete or inaccurate information We hold about You corrected.</li>
                <li>Object to processing of Your Personal Data. This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.</li>
                <li>Request the transfer of Your Personal Data. We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.</li>
                <li>Request erasure of Your Personal Data. You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.</li>
                <li>Withdraw Your consent. You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.</li>
            </ul>
            <p className='fw-bold fs-4'><HeadingAnimation text='Exercising Of Your GDPR Data Protection Rights'/></p>
            <p>You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible.</p>
            <p>You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.</p>
            <p className='fs-2 fw-bold'><HeadingAnimation text='CCPA Privacy'/></p>
            <p className='fs-4 fw-bold'><HeadingAnimation text='Your Rights Under The CCPA'/></p>
            <p>Under this Privacy Policy, and by law if You are a resident of California, You have the following rights:</p>
            <ul>
                <li>The right to notice. You must be properly notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.</li>
                <li>The right to access / the right to request. The CCPA permits You to request and obtain from the Company information regarding the disclosure of Your Personal Data that has been collected in the past 12 months by the Company or its subsidiaries to a third-party for the third party's direct marketing purposes.</li>
                <li>The right to say no to the sale of Personal Data. You also have the right to ask the Company not to sell Your Personal Data to third parties. You can submit such a request by visiting our "Do Not Sell My Personal Information" section or web page.</li>
                <li>The right to know about Your Personal Data. You have the right to request and obtain from the Company information regarding the disclosure of the following:</li>
                <ul>
                    <li>The categories of Personal Data collected</li>
                    <li>The sources from which the Personal Data was collected</li>
                    <li>The business or commercial purpose for collecting or selling the Personal Data</li>
                    <li>Categories of third parties with whom We share Personal Data</li>
                    <li>The specific pieces of Personal Data we collected about You</li>
                </ul>
                <li>The right to delete Personal Data. You also have the right to request the deletion of Your Personal Data that have been collected in the past 12 months</li>
                <li>The right not to be discriminated against. You have the right not to be discriminated against for exercising any of Your Consumer's rights, including by:</li>
                <ul>
                    <li>Denying goods or services to You</li>
                    <li>Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties</li>
                    <li>Providing a different level or quality of goods or services to You</li>
                    <li>Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
                </ul>
            </ul>

            <p className='fs-4 fw-bold'><HeadingAnimation text='Exercising Your CCPA Data Protection Rights'/></p>
            <p>In order to exercise any of Your rights under the CCPA, and if you are a California resident, You can email or call us or visit our "Do Not Sell My Personal Information" section or web page.</p>
            <p>The Company will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonable necessary and with prior notice.</p>
            
            <p className='fs-4 fw-bold'><HeadingAnimation text='Do Not Sell My Personal Information'/></p>
            <p>We do not sell personal information. However, the Service Providers we partner with (for example, our advertising partners) may use technology on the Service that "sells" personal information as defined by the CCPA law.</p>
            <p>If you wish to opt out of the use of your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below.</p>
            <p>Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that you use.</p>

            <p className=' fs-2 fw-bold'><HeadingAnimation text='Website'/></p>
            <p>You can opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service:</p>
           <ul>
                <li>From Our "Cookie Consent" notice banner</li>
                <li>Or from Our "CCPA Opt-out" notice banner</li>
                <li>Or from Our "Do Not Sell My Personal Information" notice banner</li>
                <li>Or from Our "Do Not Sell My Personal Information" link</li>
           </ul>
           <p>The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you change browsers or delete the cookies saved by your browser, you will need to opt out again.</p>
            <p className='fs-2 fw-bold'><HeadingAnimation text='Mobile Devices'/></p>
            <p>Your mobile device may give you the ability to opt out of the use of information about the apps you use in order to serve you ads that are targeted to your interests:</p>
            <p>"Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices</p>
            <p>"Limit Ad Tracking" on iOS devices</p>
            <p>You can also stop the collection of location information from Your mobile device by changing the preferences on your mobile device.</p>
       
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={5} headerTitle={<><MDBIcon fas icon="angle-double-right" /> &nbsp; "Do Not Track" Policy As Required By California Online Privacy Protection Act (CalOPPA)</>}>
            <p className='fs-4 fw-bold'>"Do Not Track" Policy As Required By California Online Privacy Protection Act (CalOPPA)</p>
            <p>Our Service does not respond to Do Not Track signals.</p>
            <p>However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.</p>
      </MDBAccordionItem>

      <MDBAccordionItem collapseId={6} headerTitle={<><MDBIcon fas icon="angle-double-right" /> &nbsp; Children's Privacy</>}>
            <p className='fs-3 fw-bold'>Children's Privacy</p>
            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
            <p>We also may limit how We collect, use, and store some of the information of Users between 13 and 18 years old. In some cases, this means We will be unable to provide certain functionality of the Service to these users.</p>
            <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={7} headerTitle={<><MDBIcon fas icon="angle-double-right" /> &nbsp; Links To Other Websites</>}>
            <p className='fs-3 fw-bold'>Links To Other Websites</p>
            <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={8} headerTitle={<><MDBIcon fas icon="angle-double-right" /> &nbsp; Changes To This Privacy Policy</>}>
            <p className='fs-3 fw-bold'>Changes To This Privacy Policy</p>
            <p>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
            <p> We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={9} headerTitle={<><MDBIcon fas icon="angle-double-right" /> &nbsp; Contact Us</>}>
            <p className='fs-3 fw-bold'>Contact Us</p>
            <p>If you have any questions about these Terms and Conditions, you can contact at info@skylabdiamond.com</p>
      </MDBAccordionItem>
    </MDBAccordion>
    </MDBContainer>
  );
}
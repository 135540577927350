import React from 'react';
import { MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';

export default function ProductGridRight(props) {

  return (
    <MDBContainer fluid className='my-5'>
      <MDBRow>
        <MDBCol md='6' lg='6'>
          <MDBRow>

            <MDBCol lg='6' md='6' className='mb-4 position-relative' data-aos="fade-up">
              <div className='border'>
                <div class="bg-image hover-overlay ripple">
                  <img src={props.regular1} className="img-fluid" alt='image' />
                  <a href="#!">
                    <div className="mask">
                      <img src={props.hover1} className="img-fluid" alt='image' />
                    </div>
                  </a>
                </div>
                <p className='text-center' id='DancingFonts'>{props.texts1}</p>
              </div>
            </MDBCol>

            <MDBCol lg='6' md='6' className='mb-4 position-relative' data-aos="fade-up">
              <div className='border'>
                <div class="bg-image hover-overlay ripple">
                  <img src={props.regular2} className="img-fluid" alt='image' />
                  <a href="#!">
                    <div className="mask">
                      <img src={props.hover2} className="img-fluid" alt='image' />
                    </div>
                  </a>
                </div>
                <p className='text-center' id='DancingFonts'>{props.texts2}</p>
              </div>
            </MDBCol>

            <MDBCol lg='6' md='6' className='my-4 position-relative' data-aos="fade-up">
              <div className='border'>
                <div class="bg-image hover-overlay ripple">
                  <img src={props.regular3} className="img-fluid" alt='image' />
                  <a href="#!">
                    <div className="mask">
                      <img src={props.hover3} class="img-fluid" alt='image' />
                    </div>
                  </a>
                </div>
                <p className='text-center' id='DancingFonts'>{props.texts3}</p>
              </div>
            </MDBCol>

            <MDBCol lg='6' md='6' className='my-4 position-relative' data-aos="fade-up">
              <div className='border'>
                <div class="bg-image hover-overlay ripple">
                  <img src={props.regular4} className="img-fluid" alt='image' />
                  <a href="#!">
                    <div className="mask">
                      <img src={props.hover4} className='img-fluid' alt='image' />
                    </div>
                  </a>
                </div>
                <p className='text-center' id='DancingFonts'>{props.texts4}</p>
              </div>
            </MDBCol>

          </MDBRow>
        </MDBCol>
        <MDBCol md='6' lg='6'><div>{props.videos}</div></MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}



import { MDBContainer} from 'mdb-react-ui-kit'
import React from 'react'
import HeadingAnimation from '../Animations/HeadingAnimation'

export default function BackImageTopText(props) {
  return (
    <MDBContainer >
          <div className='mb-4' data-aos="fade-up">
            <img src={props.backImage} className='w-100 h-100' alt='Sample'   loading="lazy"/>
          </div> 
          <div className='py-2' data-aos="fade-up">
              <h4 id="TTFonts"><HeadingAnimation text={props.heading1}/></h4>
              <p id='DancingFonts'>{props.description1}</p>
          </div>
          <div className='py-2' data-aos="fade-up">
            <h4 id="TTFonts"><HeadingAnimation text={props.heading2}/></h4>
            <p id='DancingFonts'>{props.description2}</p>
          </div>
          <div className='py-2' data-aos="fade-up">
            <h4 id="TTFonts"><HeadingAnimation text={props.heading3}/></h4>
            <p id='DancingFonts'>{props.description3}</p>
          </div>           
    </MDBContainer>
    
  )
}

import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home1 from './Components/Home1';
import About from './Components/About';
import Product from './Components/Product';
import Erorr from './Components/Erorr';
import MoreSkylabStory from './Components/About/MoreSkylabStory';
import SkylabCommitment from './Components/About/SkylabCommitment';
import SkylabProcessOfBrilliance from './Components/About/SkylabProcessOfBrilliance';
import Foundation from './Components/Foundation';
import Contact from './Components/Contact';
import Privacy from './Components/Privacy';
import { MDBContainer } from 'mdb-react-ui-kit';
import 'react-tooltip/dist/react-tooltip.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';


const App = () => {

  useEffect(() => {
    AOS.init({ duration: 800, offset: 400, })

    // Add event listener to ensure AOS updates on route changes
    window.addEventListener('load', AOS.refresh);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('load', AOS.refresh);

  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
    }, 3000);
    return () => clearTimeout(delay);

  }, []);

  return (
    // <CartProvider>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home1 />} />
            <Route path="/about" element={<About />} />
            <Route path="/product" element={<Product />} />
            <Route path="/moreskylabstory" element={<MoreSkylabStory />} />
            <Route path="/skylabcommitment" element={<SkylabCommitment />} />
            <Route path="/skylabProcessOfBrilliance" element={<SkylabProcessOfBrilliance />} />
            <Route path="/foundation" element={<Foundation />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="*" element={<Erorr />} />
          </Routes>
      </BrowserRouter>
    // </CartProvider>
  );
};

export default App;

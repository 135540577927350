import React from 'react'

export default function NavbarTopText(props) {
  return (
    <div className='bg-image' id='NavbarTopText'>
        <img src='NavbarTopText.png' className='img-fluid h-100' alt='Sample'  loading="lazy"/>
        <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.0)' }}>
            <div className='d-flex justify-content-center align-items-end h-100'>
                <div className='text-light text-center mb-5'>
                    <p>{props.pagename}</p>
                    <h1 id='TTFonts'>{props.heading}</h1>
                    <p id='DancingFonts'>{props.discription}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

// http://meraj.mypressonline.com/
import React from 'react'
import Navbars from './Navbar&Footer/Navbars';
import Video from './Home1/Video';
import ShopNow from './Home1/ShopNow';
import ShapeofDiamond from './Home1/ShapofDiamond';
import QualityMaters from './Home1/QualityMaters';
import Footers from './Navbar&Footer/Footer';
import SocialMedia from './Home1/SocialMedia';
import YourServices from './Home1/YourServices';
import InstaStory from './Home1/InstaStory';
import ClassicCollection from './Home1/ClassicCollection';
import Story from './Home1/Story';
import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import LetstakeTour from './Home1/LetstakeTour';
import MegaNavbars from './Navbar&Footer/MegaNavbars';


const Home1 = () => {
    const UrlData1 = "homepage_video.mp4";

    const subscribemail=(e)=>{
        e.preventDefault()
        alert('Thank you!')
    }
  return (
    <>
    {/* <div>
        <Navbars/>
    </div> */}
    <div>
        <MegaNavbars/>
    </div>
    <div id='TopScroll'>
        <div id='homepage-video-btn-div'>
            <Link to='http://eshop.skylabdiamond.com' id='homepage-video-btn'><MDBBtn outline color='light' className='text-capitalize'>View the Latest Inventory</MDBBtn></Link>
        </div>
        <Video videoUrl={UrlData1}  />
    </div>
    {/* <div>
        <Story/>
    </div> */}
    <div>
        <ShopNow/>
    </div>
    <div>
        <ShapeofDiamond />
    </div>
    <div>
        <QualityMaters/>
    </div>  
    <div>
        <ClassicCollection/> 
    </div>
    <div>
        <LetstakeTour/>
    </div>
    <div>
        <InstaStory/> 
    </div>
    <div>
        <YourServices/>
    </div>
    <div>
        <SocialMedia/>
    </div>
    <div>
        <MDBContainer fluid className='text-center bg-black text-light py-4'>
                <MDBContainer>
                <section className=''>
                    <form >
                        <MDBRow>
                            <MDBCol size="auto"  md='7'> 
                                <p className='pt-2 text-start'>
                                <strong>NEWSLETTER</strong>
                                <p>Subscribe to receive updates, access to exclusive deals, and more.</p>
                                </p>
                            </MDBCol>
                            <MDBCol md='5'>
                                <div className='text-end'>
                                <MDBInput type='email' placeholder='Enter your email address' className='mb-4' />
                                <MDBBtn outline color='light' className='mb-4 text-capitalize' onClick={subscribemail}>Subscribe</MDBBtn>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </section> 
                </MDBContainer>         
        </MDBContainer>
      </div>
    <div>
        <Footers/>
    </div>
    <a href='#TopScroll'><div id='Scroll-to-Top'>
        <MDBIcon fas icon="angle-double-up" />
      </div>
      </a>
    
 </> )
}

export default Home1



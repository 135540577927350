import { MDBContainer } from 'mdb-react-ui-kit'
import React from 'react'
import HeadingAnimation from '../Animations/HeadingAnimation'

export default function ChildAnEducation() {
  return (
    <MDBContainer>
                <div>
                    <h4 id="TTFonts"><HeadingAnimation text='Skylab - Shines a Light on Education: Our Sparkling Contribution'/></h4>
                    <p id='DancingFonts' className='text-start'>At Skylab, we believe in more than just creating exquisite lab-grown diamonds; we believe in the brilliance of education. We are committed to making a positive impact on society and the environment. Our Corporate Social Responsibility program is built on a strong foundation of ethical values, and one of our primary focuses is contributing to education.</p>
                    <h4 id="TTFonts"><HeadingAnimation text='A Foundation Rooted in Illuminating Minds'/></h4>
                    <p id='DancingFonts' className='text-start'>Our vision for education is simple yet profound: to empower minds, nurture dreams, and kindle the flames of knowledge. We've set our Corporate Social Responsibility program on a foundation of ethics and compassion, and we're committed to making a significant mark in the realm of education.</p>
                </div>
    </MDBContainer>
  )
}

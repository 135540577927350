import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'
import HeadingAnimation from '../Animations/HeadingAnimation'

export default function LetstakeTour() {
  return (
    <MDBContainer className='mt-5 pt-5'>
        <MDBRow>
            <MDBCol md='6' sm='12'>
                <div className='d-flex justify-content-center align-items-center h-100'>
                    <div data-aos="fade-up">
                    <p className='fs-5'>Our Manufacturing Unit</p>
                    <h2><HeadingAnimation text="LETS TAKE A TOUR"/></h2>
                    </div>
                </div>
            </MDBCol>
            <MDBCol  md='6' sm='12'>
                <div className="ratio ratio-16x9" data-aos="fade-up" >
                    <iframe src="https://lgdus.co/skylab1/Corporate_Film_01.mp4" title="YouTube video" allowfullscreen></iframe>
                </div>
            </MDBCol>
        </MDBRow>
    </MDBContainer>
  )
}

// http://meraj.mypressonline.com/
import React from 'react'
import Navbars from './Navbar&Footer/Navbars'
import NavbarTopText from './About/NavbarTopText'
import BossesCard from './Contacts/BossesCard'
import Addresses from './Contacts/Addresses'
import Footers from './Navbar&Footer/Footer'
import FormAndMaps from './Contacts/FormAndMaps'
import { MDBContainer, MDBIcon } from 'mdb-react-ui-kit'
import MegaNavbars from './Navbar&Footer/MegaNavbars'
import HeadingAnimation from './Animations/HeadingAnimation'

export default function Contact() {
  return (
    <>
      {/* <div>
        <Navbars/>
      </div> */}
      <div id='TopScroll'>
        <MegaNavbars/>
      </div>
      <div>
        <NavbarTopText heading='CONTACT' discription='' pagename=''/>
      </div>
      <div>
        {/* <BossesCard/> */}
      </div>
      {/* <div>
            <div className='py-5 text-center'>
                <p className='fs-1  text-black' id="TTFonts"><HeadingAnimation text='Contact Us'/> </p>
                <hr class="hr-text" data-content="★"/>
            </div>
            <Addresses/>
      </div> */}
      <div>
        <FormAndMaps/>
      </div>
      {/* <div>
        <MDBContainer fluid className='text-center bg-black text-light py-4'>
          <h2>Business Hours</h2>
          <p>Monday to Friday– 9.30am to 6.00pm</p>
          <p>Saturday & Sunday– Closed</p>
          <hr/>            
        </MDBContainer>
      </div> */}

      <div>
        <Footers/>
      </div>
      <a href='#TopScroll'><div id='Scroll-to-Top'>
        <MDBIcon fas icon="angle-double-up" />
      </div>
      </a>
    </>
  )
}
// http://meraj.mypressonline.com/
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import {
  MDBInput,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";
import Addresses from "../../Components/Contacts/Addresses";

export default function FormAndMaps() {
  const form = useRef();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const newErrors = {};

    if (!fullName.trim()) {
      newErrors.fullname = "Full name is required";
    }

    if (!email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is not valid";
    }

    if (!message.trim()) {
      newErrors.message = "Message is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);

      // Define custom template parameters
      const templateParams = {
        from_name: fullName, // User's name
        from_email: email, // User's email
        message: message, // User's message
        to_email: "niren@skylabdiamond.com", // Recipient email address
        reply_to: email, // Reply-to user's email
      };

      emailjs
        .send(
          "service_6wu2pm4", // Service ID
          "template_tuy624z", // Template ID
          templateParams, // Template parameters
          "Ve3BsK1zRnVcval7U" // Public Key
        )
        .then(
          (result) => {
            console.log(result.text);
            alert("Thank you! Your message has been sent successfully.");
            resetForm();
          },
          (error) => {
            console.error("Failed to send the email:", error.text);
            alert("Oops! Something went wrong. Please try again later.");
          }
        )
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const resetForm = () => {
    setFullName("");
    setEmail("");
    setMessage("");
    setSubject("");
    setErrors({});
  };

  return (
    <MDBContainer className="py-5">
      <MDBRow>
        <MDBCol lg="6" md="12" data-aos="fade-up">
          <MDBRow className="pb-4 d-flex justify-content-center"></MDBRow>
          <div>
            <form ref={form} onSubmit={sendEmail}>
              <MDBRow className="mb-4">
                <MDBCol>
                  <label>Full name *</label>
                  <MDBInput
                    id="form3Example1"
                    placeholder="Full name"
                    name="fullname"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  {errors.fullname && (
                    <div className="text-danger">{errors.fullname}</div>
                  )}
                </MDBCol>
                <MDBCol>
                  <label>Email address *</label>
                  <MDBInput
                    type="email"
                    name="email"
                    id="form3ExampleEmail"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                </MDBCol>
              </MDBRow>
              <label>Subject</label>
              <MDBInput
                className="mb-4"
                type="text"
                id="form3ExampleSubject"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <label>Message</label>
              <div>
                <textarea
                  id="form3ExampleMessage"
                  name="message"
                  rows="4"
                  className="mb-3 w-100 mx-1 p-2"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                {errors.message && (
                  <div className="text-danger">{errors.message}</div>
                )}
              </div>
              <MDBBtn
                type="submit"
                className="mb-4 bg-black text-light"
                color="black"
                block
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Send Message"}
              </MDBBtn>
            </form>
          </div>
        </MDBCol>
        <MDBCol lg="6" md="12" data-aos="fade-up" className="p-top">
          <Addresses />
          <div
            className="text-start leftt"
            style={{
              borderTop: "1px solid #000",
              paddingTop: "10px",
              paddingBottom: "50px",
            }}
          >
            <p className="m-0 ">
              <b>Business Hours:</b>
            </p>
            <p className="m-0  ">
              Monday to Friday - 9:30am to 6:00pm
              <br />
              Saturday to Sunday - Closed
            </p>
          </div>
        </MDBCol>

        <MDBCol lg="12" md="12" data-aos="fade-up">
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d162629.19337626902!2d-73.92735176888647!3d40.76484477995874!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259b2a616fd61%3A0xac795635cefcb15d!2sSkylab%20Diamond!5e0!3m2!1sen!2sin!4v1703316387586!5m2!1sen!2sin"
              width="100%"
              height="400"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

import { MDBBtn, MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'
import { Link } from 'react-router-dom'
import HeadingAnimation from '../Animations/HeadingAnimation'

export default function HistoryLeftImage(props) {
  return (
    <>
      <MDBContainer className='py-5'>
            <MDBRow>
                <MDBCol md='5' lg='6' data-aos="fade-up" className="d-flex justify-items-center align-items-center">
                  <div className='bg-image hover-zoom' style={{cursor:'pointer'}}>
                    <img src={props.images} alt=""  id='skylabStory' className='w-100'  loading="lazy"/>
                  </div>
                </MDBCol>
                <MDBCol md='7' lg='6' className='d-flex justify-content-center align-items-center' data-aos="fade-up">
                    <div className='container text-center' id='skylabStoryTextBox'>
                        <p className='fs-1 Benne-font'><HeadingAnimation text={props.heading}/></p>
                        <p id='DancingFonts' className='fs-5'>{props.discription}</p>
                        <Link to={props.btnLink} onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>
                          <button className="hoverButtonClassname" id='hoverButton'><span id='hoverButtonSpan'>LEARN MORE</span></button>
                        </Link>
                    </div>  
                </MDBCol>
            </MDBRow>
      </MDBContainer>
    </>
  )
}

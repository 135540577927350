import React from 'react'
import LearnMore from './LearnMore'
import Navbars from '../Navbar&Footer/Navbars'
import NavbarTopText from './NavbarTopText'
import Footers from '../Navbar&Footer/Footer'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import MegaNavbars from '../Navbar&Footer/MegaNavbars'
import HeadingAnimation from '../Animations/HeadingAnimation'

export default function SkylabProcessOfBrilliance() {
  return (
    <>
    {/* <div>
        <Navbars />
    </div> */}
    <div>
        <MegaNavbars/>
    </div>
    <div>
        <NavbarTopText heading='THE PROCESS' discription='' pagename=''/>
    </div>
        <div>
        <MDBContainer>
                <MDBRow> 
                    <MDBCol lg='6' md='6' className='my-5 py-5'>
                        <div className='bg-image hover-zoom' style={{cursor:'pointer'}}>
                            <img src='PROCESSOFBRILLIANCE2.jpg' alt=""  className='w-100'  loading="lazy"/>
                        </div>
                    </MDBCol>
                    <MDBCol lg='6' md='6' className='d-flex justify-content-center align-items-center '>
                        <div className='container' id='OurPhiloshpy-text-box'>
                            <p className='fs-1 text-center '><HeadingAnimation text="SKYLABʼS PROCESS OF BRILLIANCE"/></p>
                            <p id='DancingFonts' className='text-center fs-5'>Skylab Diamond crafts brilliance from the top 10% of rough slabs, shaping each with precision and subjecting it to rigorous inspections and helium tests. Our ideal stone maps, honed for angles and faceting, guarantee unmatched perfection. With a growth from 12 to over 500 craftsmen, our legacy lies in this shared craftsmanship, ensuring excellence in every diamond we produce. </p>
                        </div>  
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
        <div>
            <LearnMore 
            heading1='Handpicked Brilliance' 
            discription1='Our experts handpick from the top 10 percent of the best rough slabs. Carefully considering the size and shape, we create stunning pieces that artfully capture the essence of your story.' 
            images1='HandpickedBrilliance2.jpg' 
            images2='CraftedwithPrecision.jpg' 
            heading2='Crafted with Precision' 
            discription2='Skylab transforms the chosen rough slabs into works of art, employing cutting-edge techniques to shape and polish. With precision and skill, each piece undergoes a transformative process to emerge as a masterpiece.'
            />
        </div>
        <div>
            <LearnMore 
            heading1='Artisanal Touch' 
            discription1="Our skilled craftsmen, delicately create facets that reflect the brilliance of our diamond. This artisanal touch adds a human dimension to the diamond's journey, ensuring it resonates with a personalized charm."
            images1='ArtisanalTouch.jpg' 
            images2='OurSignature.jpg' 
            heading2='Quality is Our Signature' 
            discription2='Rigorous inspections follow, with a commitment to selecting only the best. Each diamond undergoes a two-step verification process – first, a physical inspection by the manager, and second, a helium testing process. In the latter, the diamond is compared with the standard ideal stone map, ensuring a match of at least 90 percent.'
            />
        </div>
        <div>
            <LearnMore 
            heading1='Finest Materials, Exceptional Beauty' 
            discription1='Skylab Diamond uses only the finest materials, ensuring each diamond becomes a symbol of excellence and a timeless reflection of your unique story. Our commitment to quality is embedded in every facet.' 
            images1='FinestMaterials.jpg' 
            images2='YourGuaranteeofBrilliance.jpg' 
            heading2='Your Guarantee of Brilliance' 
            discription2='Before reaching you, each Diamond undergoes a final quality check. This meticulous examination ensures that your diamond not only meets but exceeds our high standards, sparkling with the exceptional quality that defines Skylab Diamond.'
            />
        </div>


        <div>
            <div className='py-5 mt-5 text-center'>
                <p className='fs-1  text-black' id="TTFonts">Jewelry Process</p>
                {/* <p id='DancingFonts'>The beginning of a lasting tradition</p> */}
                <hr class="hr-text" data-content="★"/>
            </div>
            <LearnMore 
            heading1='Jewelry Crafted with Passion' 
            discription1='Our talented designers, with meticulous attention to sketching and detail, infuse passion into every creation, crafting unique jewelry masterpieces that embody enduring elegance.' 
            images1='JewelryCrafted.jpg' 
            images2='DiamondBrilliance.jpg' 
            heading2='Hands-On Diamond Brilliance' 
            discription2='Skylab Diamond takes pride in the in-house artistry of cutting and polishing diamonds, ensuring each gem sparkles and superior quality.'
            />
        </div>
        <div>
            <LearnMore 
            heading1='Casting with Care' 
            discription1='Our craftsmen delicately cast designs, seamlessly blending modern techniques with care to ensure the durability and beauty of each piece.' 
            images1='CastingwithCare.jpg' 
            images2='ArtistsofPlacement.jpg' 
            heading2='Artists of Placement' 
            discription2='Our skilled artisans, true masters of their craft, lovingly set each diamond, using their expertise to secure and showcase its natural beauty.'
            />  
        </div>
        
        <div>
            <LearnMore 
            heading1='Quality Assurance' 
            discription1='Every piece undergoes thorough quality checks, reflecting our dedication to delivering excellence with genuine care.' 
            images1='about_QualityAssurance.jpg' 
            images2='LuxuriousPresentation.gif' 
            heading2='Luxurious Presentation' 
            discription2='Your Skylab Diamond creation, meticulously cleaned and packaged with care, arrives as a symbol of enduring beauty and craftsmanship.'
            />
        </div>
        
        <div>
            <Footers/>
        </div>
    </>
  )
}

import React, { useState } from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBIcon } from 'mdb-react-ui-kit';
import HeadingAnimation from '../Animations/HeadingAnimation';
import ClassicCollection from "../Home1/ClassicCollection.gif"

export default function ShopNow() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };

  const cardImages = [
    {
      regular: 'classic collection 1.jpg',
      hover: 'classic collection 1.1.jpg',
    },
    {
      regular: 'ClassicCollection3.png',
      hover: 'ClassicCollection4.png',
    },
    {
      regular: 'classic collection 5.png',
      hover: 'classic collection 6.png',
    },
    {
      regular: 'classic collection 4.png',
      hover: 'classic collection 4.1.png',
    },
  ];

  return (
    <MDBContainer fluid className='my-5'>
      <div className='py-5'>
        <p className='text-center'>
          <span className='fs-1  text-black' id="TTFonts"><HeadingAnimation text="Timeless Elegance" /></span>
          <p className='fs-5'>Redefining Classic Collection</p>
        </p>

        {/* <hr class="hr-text" data-content="★"/> */}
      </div>
      <MDBRow>
        <MDBCol md='6' lg='6' data-aos="fade-up">
          <img src={ClassicCollection} alt="loading..." className="img-fix" height=""/>
        </MDBCol>

        <MDBCol md='6' lg='6' data-aos="fade-up">
          <MDBRow>
            {cardImages.map((image, index) => (
              <MDBCol key={index} lg='6' md='6' className='mb-4 position-relative' >
                <div className='border'>
                  <img
                    src={hoveredIndex === index ? image.hover : image.regular}
                    style={{
                      transition: 'all 1s',
                      // Add other styles as needed
                    }}
                    className='img-fluid'
                    alt=''
                    onMouseOver={() => handleMouseOver(index)}
                    onMouseOut={handleMouseOut}
                    id='shopNowBox'
                  />
                  {hoveredIndex === index && (
                    <div className='position-absolute bottom-0 end-50 translate-middle-x'>
                      {/* Replace the below icon with your desired icon */}
                      {/* <MDBIcon fas icon="gem"  className='fs-4'/> */}
                    </div>
                  )}
                  <p className='text-center' id='DancingFonts'>{image.texts}</p>
                </div>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}


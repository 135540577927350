import React, { useState } from 'react';
import { MDBContainer, MDBCol, MDBRow} from 'mdb-react-ui-kit';
import HeadingAnimation from '../Animations/HeadingAnimation';
import { ClassNames } from '@emotion/react';


export default function ShopNow() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };

  const cardImages = [
    {
      regular: 'homepage-1.jpg',
      hover: 'homepage-1.1.jpg',
    },
    {
      regular: 'homepage-2.png',
      hover: 'homepage-2.1.png',
    },
    {
      regular: 'homepage-3.png',
      hover: 'homepage-3.1.png',
    },
  ];

  return (
    <MDBContainer className='my-5'>
    <div className='p-5'>
   <p className='text-center text-black' id="TTFonts"><span id='HeadingText-Animation'>View Our Collection</span></p>
        <hr class="hr-text" data-content="★"/>
        <p className='text-center fs-1  text-black ' id="TTFonts"><HeadingAnimation text="Jewelry"/></p>

    </div> 
      <MDBRow>
        {cardImages.map((image, index) => (
          <MDBCol key={index} lg='4' md='4' className='mb-4 position-relative' data-aos="fade-up">
            
            <img
              src={hoveredIndex === index ? image.hover : image.regular}
              style={{ 
                transition: 'all 1s',
                // Add other styles as needed
              }}
              className='img-fluid shadow-2-strong '
              alt=''
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
              id='shopNowBox'
              
            />
            
            {hoveredIndex === index && (
              <div className='position-absolute bottom-0 end-50 translate-middle-x'>
                {/* Replace the below icon with your desired icon */}
                {/* <MDBIcon fas icon="gem"  className='fs-4'/> */}
              </div>
            )}
         
          </MDBCol>
        ))}
      </MDBRow>
    </MDBContainer>
  );
}


import { MDBIcon } from 'mdb-react-ui-kit'
import React from 'react'

export default function SocialMedia() {
  return (
    <section className='d-flex justify-content-center align-item-center py-5'>    
        <div>
          <a href='https://www.facebook.com/skylabdiamonds/' className='me-4 text-reset fs-4'>
            <MDBIcon fab icon='facebook-f' id='facebook-icon'/>
          </a>
          {/* <a href='https://twitter.com/i/flow/login?redirect_after_login=%2FSkyLabDiamond%2F' className='me-4 text-reset fs-4'>
            <MDBIcon fab icon='twitter' id='twitter-icon'/>
          </a> */}
          {/* <a href='' className='me-4 text-reset fs-4'>
            <MDBIcon fab icon='google' id='google-icon'/>
          </a> */}
          <a href='https://www.instagram.com/skylabdiamond/' className='me-4 text-reset fs-4'>
            <MDBIcon fab icon='instagram' id='instagram-icon'/>
          </a>
          <a href='https://www.linkedin.com/company/skylab-diamondinc/' className='me-4 text-reset fs-4'>
            <MDBIcon fab icon='linkedin' id='linkedin-icon'/>
          </a>
        </div>
      </section> 
  )
}
